import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { getSocket, socketConnected, getSymOrder } from './Socket';
import { createChartInfoObject, createChartStrideObject, createChartFootfallsObject, formatTime, averageSignal, getFootfallOptions, getZoomScale, getScaleWidth } from './Chart';
import { getPages, getNavigation } from './App';
import { getFile, putFile, removeFile } from './IndexedDB';
import * as cbor from 'cbor-web';
import { Avatar, Box, Button, Checkbox, CssBaseline, Dialog, DialogActions, DialogContent, Divider, Drawer, FormControl, FormControlLabel, IconButton } from '@mui/material';
import { InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, Select, Slider, Stack, TextField, Tooltip } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    ArrowBackRounded as ArrowBackRoundedIcon,
    Cached as CachedIcon,
    Comment as CommentIcon,
    Download as DownloadIcon,
    Edit as EditIcon,
    Menu as MenuIcon,
    Save as SaveIcon,
} from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { Scatter } from 'react-chartjs-2'
import { MapContainer, TileLayer, Polyline, useMap } from 'react-leaflet';
import sanitize from 'sanitize-filename';

const minSats = 8;
const infoHeight = '210px'; // height of info and comment boxes

const STATE_NOT_LOADED = 0;
const STATE_LOADING = 1;
const STATE_LOADED = 2;
const STATE_OUTDATED = 3;
const STATE_NOT_FOUND = 4;
const STATE_DOWNLOADING = 5;
const STATE_DOWNLOADED = 6;
const STATE_DOWNLOAD_DATA_ERROR = 7;
const STATE_DOWNLOAD_ERROR = 8;


function calcMeanStdDev(data) {
    var mean = null;
    var stddev = null;
    var max = null;

    if (data && data.length > 0) {
        mean = data.reduce((a, b) => a + b) / data.length;
        stddev = Math.sqrt(data.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / data.length);
        max = Math.max(...data);
    }
    return { mean, stddev, max };
};


class Details extends Component {
    constructor(props) {
        super(props);

        this.defaultTheme = createTheme({ palette: { mode: 'dark' } });

        // allow to use 'this' to access class members
        this.connectHandler = this.connectHandler.bind(this);
        this.measurementHandler = this.measurementHandler.bind(this);
        this.userHandler = this.userHandler.bind(this);
        this.horseHandler = this.horseHandler.bind(this);

        // remember last selected measurement/system so it can be shown after a refresh
        var measurementID = this.props.measurement?.measurementID;
        if (!measurementID)
            measurementID = localStorage.getItem('detailsMeasurementID');
        localStorage.setItem('detailsMeasurementID', measurementID);
        var systemID = this.props.measurement?.systemID;
        if (!systemID)
            systemID = parseInt(localStorage.getItem('detailsSystemID')) || 0;
        localStorage.setItem('detailsSystemID', systemID);
        this.props.setMeasurement(undefined);

        this.state = {
            isConnected: socketConnected(),
            navigate: undefined,
            drawerOpen: false,
            clearConfirm: false,
            measurement: null,
            users: [],
            horses: [],
            trainingTypes: [],
            measurementID: measurementID,
            systemID: systemID,
            slider: {
                min: 0,
                max: 0,
                range: [0, 0],
                hasRange: false,
            },
            selection: {
                id: 0,
                remove: false,
                add: false,
                received: false,
            },
            comment: '',
            commentSaveIconColor: undefined,
            notesSegmentID: undefined,
            changeHorseID: undefined,
            changeTrainingTypeID: undefined,
            showStdDev: parseInt(localStorage.getItem('detailsShowStdDev')) ? true : false,
            showMaximum: parseInt(localStorage.getItem('detailsShowMaximum')) ? true : false,
            showFootfalls: parseInt(localStorage.getItem('detailsShowFootfalls')) ? true : false,
        }

        this.measurementState = STATE_NOT_LOADED;
        this.measurementData = undefined;
        this.chartInfo = createChartInfoObject();
        this.chartStride = createChartStrideObject();
        this.chartFootfalls = createChartFootfallsObject();

        const scaleChangeFn = () => {
            this.setState({});
        }
        this.chartInfo.options.scaleChangeFn = scaleChangeFn;
        this.chartStride.options.scaleChangeFn = scaleChangeFn;
        this.chartFootfalls.options.scaleChangeFn = scaleChangeFn;

        this.mapBounds = undefined;
        this.lastCommentCount = undefined;
    }

    componentDidMount() {
        this.loadMeasurement();

        var socket = getSocket();
        if (socket) {
            socket.on('connect', this.connectHandler);
            socket.on('disconnect', this.connectHandler);
            socket.on('measurements', this.measurementHandler);
            socket.on('users', this.userHandler);
            socket.on('horses', this.horseHandler);

            this.getUsers();
            this.getHorses();
            this.getTrainingTypes();
            this.getMeasurement();
        }

        const L = require('leaflet');
        delete L.Icon.Default.prototype._getIconUrl;
        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
            iconUrl: require('leaflet/dist/images/marker-icon.png'),
            shadowUrl: require('leaflet/dist/images/marker-shadow.png')
        });
    }

    setMeasurementState(newState, update = true) {
        this.measurementState = newState;
        if (update)
            this.forceUpdate();
    }

    getMeasurementStateDescription() {
        switch (this.measurementState) {
            case STATE_NOT_LOADED:
                return 'not loaded';
            case STATE_LOADING:
                return 'loading';
            case STATE_LOADED:
                return 'loaded';
            case STATE_OUTDATED:
                return 'outdated';
            case STATE_NOT_FOUND:
                return 'not found';
            case STATE_DOWNLOADING:
                return 'downloading';
            case STATE_DOWNLOADED:
                return 'downloaded';
            case STATE_DOWNLOAD_DATA_ERROR:
                return 'download data error';
            case STATE_DOWNLOAD_ERROR:
                return 'download error';
            default:
                return 'unknown';
        }
    }

    componentWillUnmount() {
        var socket = getSocket();
        if (socket) {
            socket.off('connect', this.connectHandler);
            socket.off('disconnect', this.connectHandler);
            socket.off('measurements', this.measurementHandler);
            socket.off('users', this.userHandler);
            socket.off('horses', this.horseHandler);
        }

        const destroyChart = (chart) => {
            const chartRef = chart?.reference?.current;
            if (chartRef)
                chartRef.destroy();
        }
        destroyChart(this.chartInfo);
        destroyChart(this.chartStride);
        destroyChart(this.chartFootfalls);
    }

    checkMeasurement() {
        if (this.measurementState === STATE_NOT_FOUND) {
            this.downloadMeasurement({ measurementID: this.state.measurementID, systemID: this.state.systemID, fileType: 'results' });
        }
    }

    async loadMeasurement() {
        this.setMeasurementState(STATE_LOADING);
        this.measurementData = await getFile(this.state.measurementID, this.state.systemID);
        if (this.chartInfo)
            this.chartInfo.created = false;
        if (this.chartStride)
            this.chartStride.created = false;
        if (this.chartFootfalls)
            this.chartFootfalls.created = false;
        this.mapBounds = undefined;
        this.setMeasurementState(this.measurementData ? STATE_LOADED : STATE_NOT_FOUND);

        this.processSegments(this.state.measurement);
    }

    getMeasurement() {
        var socket = getSocket();
        socket.emit('measurements', 'request', 'get', {
            measurementID: this.state.measurementID,
        });
    }

    getUsers() {
        var socket = getSocket();
        socket.emit('users', 'request', 'get');
    }

    getHorses() {
        var socket = getSocket();
        socket.emit('horses', 'request', 'get');
    }

    getTrainingTypes() {
        var socket = getSocket();
        socket.emit('measurements', 'request', 'getTrainingTypes');
    }

    connectHandler(reason) {
        this.setState({ isConnected: socketConnected() });

        if (reason === 'io server disconnect')
            this.getUsers();
    }

    measurementHandler(channel, subChannel, data) {
        if (channel === 'response') {
            if (subChannel === 'get') {
                var measurement = (data && data.length > 0) ? data[0] : null;
                var selection = this.state.selection;
                selection.received = true;
                this.setState({ measurement, selection });
                this.processSegments(measurement);
            }
            else if (subChannel === 'addComment') {
                if (this.state.commentSaveIconColor === 'warning') {
                    this.setState({
                        comment: '',
                        commentSaveIconColor: data ? 'success' : 'error',
                    });
                    setTimeout(() => {
                        this.setState({ commentSaveIconColor: undefined });
                    }, 3000);
                }
                this.getMeasurement();
            }
            else if (subChannel === 'getTrainingTypes') {
                this.setState({ trainingTypes: data });
            }
            else {
                this.getMeasurement();
            }
        }
    }

    userHandler(channel, subChannel, data) {
        if (channel === 'response') {
            if (subChannel === 'get') {
                this.setState({ users: data });
            }
        }
    }

    horseHandler(channel, subChannel, data) {
        if (channel === 'response') {
            if (subChannel === 'get') {
                this.setState({ horses: data });
            }
            else {
                this.getHorses();
            }
        }
    }

    getInfoFromHeaders(headers) {
        var result = {
            contentLength: 0,
            contentType: '',
            contentDisposition: '',
            lastModified: new Date(),
        };

        if (!headers)
            return result;

        try {
            result.contentLength = parseInt(headers.get('content-length'));
        } catch (error) {
            console.warn('error getting contentLength: ' + error);
        }
        try {
            result.contentType = headers.get('content-type');
        } catch (error) {
            console.warn('error getting contentType: ' + error);
        }
        try {
            result.contentDisposition = headers.get('content-disposition');
        } catch (error) {
            console.warn('error getting contentDisposition: ' + error);
        }
        try {
            result.lastModified = new Date(headers.get('last-modified'));
        } catch (error) {
            console.warn('error getting lastModified: ' + error);
        }

        return result;
    }

    downloadMeasurement(measurementInfo) {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user || !user.token) {
            return;
        }

        if (this.measurementState !== STATE_NOT_FOUND)
            return;

        this.setMeasurementState(STATE_DOWNLOADING, false);
        var fileInfo = undefined;

        var apiServer = process.env.REACT_APP_API_SERVER;
        fetch(apiServer + '/download', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + user.token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(measurementInfo),
        })
            .then((r) => {
                if (!r.ok)
                    throw new Error(r.status + ': ' + r.statusText);

                fileInfo = this.getInfoFromHeaders(r.headers);

                if (fileInfo.contentType.includes('json'))
                    return r.json();
                else
                    return r.arrayBuffer();
            })
            .then(async (buffer) => {
                try {
                    var data = fileInfo.contentType.includes('json') ? buffer : cbor.decode(buffer);
                    await putFile(measurementInfo.measurementID, this.state.systemID, data, fileInfo);
                    this.setMeasurementState(STATE_DOWNLOADED);
                    this.loadMeasurement();
                } catch (error) {
                    // console.log(error);
                    this.setMeasurementState(STATE_DOWNLOAD_DATA_ERROR);
                }
            })
            .catch(error => {
                // console.log(error);
                this.setMeasurementState(STATE_DOWNLOAD_ERROR);
            });
    }

    downloadFile(measurementInfo) {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user || !user.token) {
            return;
        }

        var fileInfo = undefined;

        var apiServer = process.env.REACT_APP_API_SERVER;
        fetch(apiServer + '/download', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + user.token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(measurementInfo),
        })
            .then((r) => {
                if (!r.ok)
                    throw new Error(r.status + ': ' + r.statusText);

                fileInfo = this.getInfoFromHeaders(r.headers);

                return r.blob();
            })
            .then(blob => {
                var filename = 'data.bin';

                // get original filename from contentDisposition header
                if (fileInfo.contentDisposition && fileInfo.contentDisposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(fileInfo.contentDisposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }

                // change filename to measurement timestamp in local time and add horse name
                var measurement = this.state.measurement;
                if (measurement) {
                    var date = new Date(measurement.date);
                    const pad = (num) => (num < 10 ? '0' : '') + num;
                    var ts = date.getFullYear() + pad(date.getMonth() + 1) + pad(date.getDate()) + 'T' + pad(date.getHours()) + pad(date.getMinutes()) + pad(date.getSeconds());

                    var fileComponents = filename.split('.');
                    var ext = fileComponents.length > 0 ? fileComponents.pop() : 'bin';
                    var fileType = fileComponents.length > 0 ? fileComponents.pop().split('_').pop() : 'data';

                    var horseName = '';
                    if (measurement.systems) {
                        var system = measurement.systems.find((item) => item.userID === this.state.systemID);
                        if (system)
                            horseName = this.state.horses.find((item) => item.horseID === system.horseID)?.name || '';
                    }

                    filename = ts + '_' + fileType + (horseName ? (' ' + sanitize(horseName)) : '') + '.' + ext;
                }

                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    clearCharts() {
        const clearChart = (chart) => {
            const chartRef = chart?.reference?.current;
            if (chartRef?.data?.datasets) {
                var ds = chartRef.data.datasets;
                for (var datasetIndex = 0; datasetIndex < ds.length; ++datasetIndex) {
                    ds[datasetIndex].data = [];
                }
                chartRef.update('none');
            }
        }
        clearChart(this.chartInfo);
        clearChart(this.chartStride);
        clearChart(this.chartFootfalls);
    }

    createChartInfo() {
        if (!this.chartInfo || this.chartInfo.created)
            return;

        var result = this.measurementData;
        const chartInfo = this.chartInfo?.reference?.current;
        var ds = chartInfo?.data?.datasets;
        if (!result || !chartInfo || !ds)
            return;

        this.chartInfo.created = true;

        var i = 0;
        var hrPoints = [];
        var symmetryPoints = [];
        var speedPoints = [];
        var satPoints = [];
        var altPoints = [];

        var tl = result.timeline;
        if (tl) {
            hrPoints.push([tl.start, null]);
            symmetryPoints.push([tl.start, null]);
            speedPoints.push([tl.start, null]);
            satPoints.push([tl.start, null]);
            altPoints.push([tl.start, null]);
        }

        if (result.heartRate?.signal) {
            var hrX = result.heartRate.signal.x;
            var hrY = result.heartRate.signal.hr;
            if (hrX && hrY) {
                for (i = 0; i < hrX.length && i < hrY.length; ++i) {
                    var yVal = hrY[i];
                    if (yVal === 0)
                        yVal = NaN;
                    hrPoints.push([hrX[i], yVal]);
                }
            }
        }

        for (var iv = 0; tl && tl.intervals && iv < tl.intervals.length; ++iv) {
            var interval = tl.intervals[iv];
            if (!interval)
                continue;

            // separation between intervals
            symmetryPoints.push([interval.start, null]);
            speedPoints.push([interval.start, null]);
            satPoints.push([interval.start, null]);
            altPoints.push([interval.start, null]);

            // symmetry
            var symreg = interval?.symreg;
            if (symreg && symreg.x) {
                var symregY = undefined;
                for (var pos of getSymOrder()) {
                    if (symregY)
                        break;
                    symregY = symreg[pos];
                }
                if (symregY && symregY.symmetry) {
                    for (i = 0; i < symreg.x.length && i < symregY.symmetry.length; ++i) {
                        symmetryPoints.push([symreg.x[i], symregY.symmetry[i]]);
                    }
                }
            }

            // gnss
            if (interval.gnss) {
                for (var gnss of Object.values(interval.gnss)) {
                    for (i = 0; gnss && i < gnss.length; ++i) {
                        if (!gnss[i])
                            continue;

                        var gnssX = gnss[i].time;
                        var sat = gnss[i].numberSvsInFix;
                        satPoints.push([gnssX, sat]);

                        var speed = gnss[i].valid ? (gnss[i].speedOverGround * 3.6) : null;
                        if (sat < minSats)
                            speed = null;
                        speedPoints.push([gnssX, speed]);

                        var altitude = gnss[i].valid ? (gnss[i].altitudeMSL) : null;
                        if (sat < minSats)
                            altitude = null;
                        altPoints.push([gnssX, altitude]);
                    }

                    // support only 1 gnss position
                    if (speedPoints.length > 0)
                        break;
                }
            }

            symmetryPoints.push([interval.end, null]);
            speedPoints.push([interval.end, null]);
            satPoints.push([interval.end, null]);
            altPoints.push([interval.end, null]);
        }

        if (tl) {
            hrPoints.push([tl.end, null]);
            symmetryPoints.push([tl.end, null]);
            speedPoints.push([tl.end, null]);
            satPoints.push([tl.end, null]);
            altPoints.push([tl.end, null]);
        }

        speedPoints = averageSignal(speedPoints, 5);
        satPoints = averageSignal(satPoints, 5);
        altPoints = averageSignal(altPoints, 5);

        for (var datasetIndex = 0; datasetIndex < ds.length; ++datasetIndex) {
            var dataset = ds[datasetIndex];
            switch (datasetIndex) {
                case 0: {
                    dataset.data = symmetryPoints;
                    break;
                }
                case 1: {
                    dataset.data = hrPoints;
                    break;
                }
                case 2: {
                    dataset.data = speedPoints;
                    break;
                }
                case 3: {
                    dataset.data = satPoints;
                    break;
                }
                case 4: {
                    dataset.data = altPoints;
                    break;
                }
                default:
                    break;
            }
        }

        chartInfo.update('none');
    }

    createChartStride() {
        if (!this.chartStride || this.chartStride.created)
            return;

        var result = this.measurementData;
        const chartStride = this.chartStride?.reference?.current;
        var ds = chartStride?.data?.datasets;
        if (!result || !chartStride || !ds)
            return;

        this.chartStride.created = true;

        var i = 0;
        var frequencyPoints = [];
        var lengthPoints = [];

        var tl = result.timeline;
        if (tl) {
            frequencyPoints.push([tl.start, null]);
            lengthPoints.push([tl.start, null]);
        }

        for (var iv = 0; tl && tl.intervals && iv < tl.intervals.length; ++iv) {
            var interval = tl.intervals[iv];
            if (!interval)
                continue;

            // separation between intervals
            frequencyPoints.push([interval.start, null]);
            lengthPoints.push([interval.start, null]);

            // stride info
            var strideInfo = interval?.strideInfo;
            if (strideInfo && strideInfo.x) {
                for (i = 0; i < strideInfo.x.length; ++i) {
                    if (strideInfo.frequency && i < strideInfo.frequency.length)
                        frequencyPoints.push([strideInfo.x[i], strideInfo.frequency[i]]);
                    if (strideInfo.length && i < strideInfo.length.length)
                        lengthPoints.push([strideInfo.x[i], strideInfo.length[i]]);
                }
            }

            frequencyPoints.push([interval.end, null]);
            lengthPoints.push([interval.end, null]);
        }

        if (tl) {
            frequencyPoints.push([tl.end, null]);
            lengthPoints.push([tl.end, null]);
        }

        for (var datasetIndex = 0; datasetIndex < ds.length; ++datasetIndex) {
            var dataset = ds[datasetIndex];
            switch (datasetIndex) {
                case 0: {
                    dataset.data = lengthPoints;
                    break;
                }
                case 1: {
                    dataset.data = frequencyPoints;
                    break;
                }
                default:
                    break;
            }
        }

        chartStride.update('none');
    }

    createChartFootfalls() {
        if (!this.chartFootfalls || this.chartFootfalls.created || !this.state.showFootfalls)
            return;

        var result = this.measurementData;
        const chartFootfalls = this.chartFootfalls?.reference?.current;
        var ds = chartFootfalls?.data?.datasets;
        if (!result || !chartFootfalls || !ds)
            return;

        this.chartStride.created = true;
        var footfallOptions = getFootfallOptions();
        var tl = result.timeline;

        for (var i = 0; i < 4; ++i) {
            var points = [];

            if (tl) {
                points.push({ x: tl.start, y: null });
            }

            for (var iv = 0; tl && tl.intervals && iv < tl.intervals.length; ++iv) {
                var interval = tl.intervals[iv];
                if (!interval)
                    continue;

                // separation between intervals
                points.push({ x: interval.start, y: null });

                var src = (i === 0) ? interval.footfalls?.rh : ((i === 1) ? interval.footfalls?.lh : ((i === 2) ? interval.footfalls?.rf : interval.footfalls?.lf));
                if (!src)
                    continue;

                var opt = (i === 0) ? footfallOptions.rh : ((i === 1) ? footfallOptions.lh : ((i === 2) ? footfallOptions.rf : footfallOptions.lf));
                for (var j = 0; j < src.length; ++j) {
                    var xVal = src[j];
                    var hoofOff = (xVal.hoof_off !== undefined) ? xVal.hoof_off : xVal.start;
                    var overlapFix = undefined;
                    if (points.length > 0 && hoofOff === points[points.length - 1].x) {
                        overlapFix = 0.01;
                        hoofOff += overlapFix;
                    }

                    if (xVal.intensity === null) {
                        // point between merged section
                        points.push({
                            x: hoofOff,
                            y: NaN,
                        });
                        continue;
                    }

                    points.push({
                        x: hoofOff,
                        y: opt.pos,
                        overlapFix,
                    });
                    points.push({
                        x: hoofOff,
                        y: NaN,
                        overlapFix,
                    });
                    points.push({
                        x: (xVal.hoof_on !== undefined) ? xVal.hoof_on : xVal.end,
                        y: opt.pos,
                    });
                }

                points.push({ x: interval.end, y: null });
            }

            if (tl) {
                points.push({ x: tl.end, y: null });
            }

            if (i < ds.length) {
                ds[i].data = points;
            }
        }

        chartFootfalls.update('none');
    }

    initSlider() {
        if (this.state.slider.hasRange)
            return;

        var timeMin = null;
        var timeMax = null;

        var tl = this.measurementData?.timeline;
        if (tl) {
            timeMin = tl.start;
            timeMax = tl.end;
        }

        if (timeMin !== null && timeMax !== null && timeMax > timeMin) {
            this.setState({
                slider: {
                    min: timeMin,
                    max: timeMax,
                    range: [timeMin, timeMax],
                    hasRange: true,
                }
            });
        }
    }

    getCompareItems() {
        var compareItems = [];
        try {
            compareItems = JSON.parse(localStorage.getItem('compareItems'));
        } catch (e) {
            console.log(e);
        }
        if (!Array.isArray(compareItems))
            compareItems = [];

        return compareItems;
    }

    processSegments(measurement) {
        if (!measurement || !measurement.systems)
            return;
        if (!this.measurementData)
            return;
        var system = measurement.systems.find((item) => item.userID === this.state.systemID);
        if (!system)
            return;

        if (system.version && system.version !== this.measurementData.algorithmVersion)
            this.setMeasurementState(STATE_OUTDATED);

        if (!system.segments)
            return;

        var compareItems = this.getCompareItems();

        var i = 0, xVal = null, yVal = null;
        for (const segment of system.segments) {
            if (!segment || !segment.details || !segment.details.range || segment.details.range.length < 2)
                continue;

            var startTime = segment.details.range[0];
            var endTime = segment.details.range[1];

            var hrData = [];
            var symData = [];
            var satsData = [];
            var speedData = [];
            var lenData = [];
            var freqData = [];

            var hrX = this.measurementData.heartRate?.signal?.x;
            var hrY = this.measurementData.heartRate?.signal?.hr;
            for (i = 0; hrX && hrY && i < hrX.length && i < hrY.length; ++i) {
                xVal = hrX[i];
                yVal = hrY[i];
                if (xVal >= startTime && xVal <= endTime && !isNaN(yVal)) {
                    hrData.push(yVal);
                }
            }

            var ivs = this.measurementData.timeline?.intervals;
            for (var iv = 0; ivs && iv < ivs.length; ++iv) {
                var interval = ivs[iv];

                // symmetry
                var symreg = interval?.symreg;
                if (symreg && symreg.x) {
                    var symregY = undefined;
                    for (var pos of getSymOrder()) {
                        if (symregY)
                            break;
                        symregY = symreg[pos];
                    }
                    if (symregY && symregY.symmetry) {
                        for (i = 0; i < symreg.x.length && i < symregY.symmetry.length; ++i) {
                            xVal = symreg.x[i];
                            yVal = symregY.symmetry[i];
                            if (xVal >= startTime && xVal <= endTime && !isNaN(yVal)) {
                                symData.push(yVal);
                            }
                        }
                    }
                }

                // gnss
                if (interval.gnss) {
                    var firstGNSS = true;
                    for (var gnss of Object.values(interval.gnss)) {
                        for (i = 0; gnss && i < gnss.length; ++i) {
                            if (!gnss[i])
                                continue;
                            firstGNSS = false;

                            xVal = gnss[i].time;
                            if (xVal >= startTime && xVal <= endTime) {
                                yVal = gnss[i].numberSvsInFix;
                                if (!isNaN(yVal))
                                    satsData.push(yVal);
                                var ignoreSpeed = yVal < minSats;
                                yVal = gnss[i].valid ? (gnss[i].speedOverGround * 3.6) : NaN;
                                if (!ignoreSpeed && !isNaN(yVal)) {
                                    speedData.push(yVal);
                                }
                            }
                        }

                        // support only 1 gnss position
                        if (!firstGNSS)
                            break;
                    }
                }

                // stride info
                var strideInfo = interval?.strideInfo;
                if (strideInfo && strideInfo.x) {
                    for (i = 0; i < strideInfo.x.length; ++i) {
                        xVal = strideInfo.x[i];
                        if (xVal >= startTime && xVal <= endTime) {
                            if (strideInfo.frequency && i < strideInfo.frequency.length && !isNaN(strideInfo.frequency[i]))
                                freqData.push(strideInfo.frequency[i]);
                            if (strideInfo.length && i < strideInfo.length.length && !isNaN(strideInfo.length[i]))
                                lenData.push(strideInfo.length[i]);
                        }
                    }
                }
            }

            segment.details.heartRate = calcMeanStdDev(hrData);
            segment.details.symmetry = calcMeanStdDev(symData);
            segment.details.satellites = calcMeanStdDev(satsData);
            segment.details.speed = calcMeanStdDev(speedData);
            segment.details.strideLength = calcMeanStdDev(lenData);
            segment.details.strideFrequency = calcMeanStdDev(freqData);

            var compareItem = compareItems.find(item => item.measurementID === this.state.measurementID && item.systemID === this.state.systemID && item.segmentID === segment.date);
            if (compareItem) {
                compareItem.details = segment.details;
            }
        }

        localStorage.setItem('compareItems', JSON.stringify(compareItems));
        this.setState({ measurement });
    }

    updateSliderLines() {
        const updateMarkerDataset = (chart, slider, timelineRange) => {
            const chartRef = chart?.reference?.current;
            var ds = chartRef?.data?.datasets;
            if (ds && ds.length >= 2 && slider.hasRange) {
                ds[ds.length - 3].data = [[timelineRange.min, NaN], [timelineRange.max, NaN]];
                ds[ds.length - 2].data = [];
                ds[ds.length - 1].data = [];
                for (var markerY = 0; markerY <= 10; ++markerY) {
                    ds[ds.length - 2].data.push([slider.range[0], markerY / 10]);
                    ds[ds.length - 1].data.push([slider.range[1], markerY / 10]);
                }
                chartRef.update('none');
            }
        }

        var timelineRange = {
            min: this.measurementData?.timeline?.start,
            max: this.measurementData?.timeline?.end,
        };

        updateMarkerDataset(this.chartInfo, this.state.slider, timelineRange);
        updateMarkerDataset(this.chartStride, this.state.slider, timelineRange);
        updateMarkerDataset(this.chartFootfalls, this.state.slider, timelineRange);
    }

    resetChartZoom() {
        const chartInfo = this.chartInfo?.reference?.current;
        if (chartInfo)
            chartInfo.resetZoom();
        const chartStride = this.chartStride?.reference?.current;
        if (chartStride)
            chartStride.resetZoom();
        const chartFootfalls = this.chartFootfalls?.reference?.current;
        if (chartFootfalls)
            chartFootfalls.resetZoom();

        this.setSliderRange();
    }

    setSliderRange(range) {
        var slider = this.state.slider;

        if (range)
            slider.range = range;

        var chartScale = getZoomScale();

        var tl = this.measurementData?.timeline;
        if (tl) {
            if (chartScale.min < tl.start)
                chartScale.min = tl.start;
            if (chartScale.max > tl.end)
                chartScale.max = tl.end;
        }

        if (chartScale.min !== undefined)
            slider.min = chartScale.min;
        if (chartScale.max !== undefined)
            slider.max = chartScale.max;

        this.setState({ slider });
    }

    renderCharts() {
        setTimeout(() => {
            this.createChartInfo();
            this.createChartStride();
            this.createChartFootfalls();
            this.initSlider();
        }, 0);

        const onResetZoom = () => {
            this.resetChartZoom();
        };
        this.updateSliderLines();

        const onSlider = (event, newValue) => {
            this.setSliderRange(newValue);

            var selection = this.state.selection;
            selection.id = 0;
            this.setState({ selection });
        };

        var addSegment = () => {
            var segmentInfo = {
                range: this.state.slider.range,
                notes: '',
            }

            var selection = this.state.selection;
            selection.add = true;
            this.setState({ selection });

            var socket = getSocket();
            socket.emit('measurements', 'request', 'addSegment', {
                measurementID: this.state.measurementID,
                systemID: this.state.systemID,
                segment: segmentInfo,
            });
        };

        return (
            <Box className='sub-box'>
                <Box className='chart-container'>
                    <Box style={{ height: this.state.showFootfalls ? '45%' : '60%' }}>
                        <Scatter ref={this.chartInfo.reference} options={this.chartInfo.options} data={this.chartInfo.data} />
                    </Box>
                    <Box style={{ height: this.state.showFootfalls ? '30%' : '40%' }}>
                        <Scatter ref={this.chartStride.reference} options={this.chartStride.options} data={this.chartStride.data} />
                    </Box>
                    {this.state.showFootfalls ? <Box style={{ height: '25%' }}>
                        <Scatter ref={this.chartFootfalls.reference} options={this.chartFootfalls.options} data={this.chartFootfalls.data} />
                    </Box> : undefined}
                </Box>
                <Button
                    variant='outlined'
                    sx={{
                        display: 'block',
                        margin: '0 2rem 0.5rem auto',
                    }}
                    onClick={onResetZoom}>
                    Reset Zoom
                </Button>
                <Divider variant='middle' />
                <Box
                    sx={{
                        width: '100%',
                        paddingTop: '2rem',
                        paddingLeft: 'calc(1rem + ' + getScaleWidth('left') + ')',
                        paddingRight: 'calc(3rem + ' + getScaleWidth('right') + ')',
                        paddingBottom: '0rem',
                    }}
                >
                    <Slider
                        disabled={!this.state.slider.hasRange}
                        value={this.state.slider.range}
                        min={this.state.slider.min}
                        max={this.state.slider.max}
                        onChange={onSlider}
                        valueLabelDisplay='on'
                        valueLabelFormat={formatTime}
                    />
                    <Button
                        sx={{ margin: '0 auto', display: 'block' }}
                        variant='outlined'
                        disabled={!this.state.slider.hasRange}
                        onClick={addSegment}
                    >Add</Button>
                </Box>
            </Box>
        );
    }

    renderMap() {
        var sensorName = undefined;
        var calcBounds = this.mapBounds === undefined;

        var pointList = [];
        var selectedPointList = [];

        var ivs = this.measurementData?.timeline?.intervals || [];
        for (const iv of ivs) {
            var gnssData = iv.gnss;
            if (!gnssData)
                continue;

            for (const [key, value] of Object.entries(gnssData)) {
                if (!key || !value || value.length === 0)
                    continue;
                if (sensorName === undefined)
                    sensorName = key;
                if (key !== sensorName)
                    continue;

                var lastSecond = -1;
                var points = [];
                var selectionPoints = [];

                for (const gnssSample of value) {
                    if (!gnssSample.valid || gnssSample.numberSvsInFix < minSats) {
                        if (points.length > 0) {
                            pointList.push(points);
                            points = [];
                        }
                        if (selectionPoints.length > 0) {
                            selectedPointList.push(selectionPoints);
                            selectionPoints = [];
                        }
                        continue;
                    }

                    if (calcBounds) {
                        if (this.mapBounds === undefined)
                            this.mapBounds = [[undefined, undefined], [undefined, undefined]];
                        if (this.mapBounds[0][0] === undefined || gnssSample.latitude < this.mapBounds[0][0])
                            this.mapBounds[0][0] = gnssSample.latitude;
                        if (this.mapBounds[1][0] === undefined || gnssSample.latitude > this.mapBounds[1][0])
                            this.mapBounds[1][0] = gnssSample.latitude;
                        if (this.mapBounds[0][1] === undefined || gnssSample.longitude < this.mapBounds[0][1])
                            this.mapBounds[0][1] = gnssSample.longitude;
                        if (this.mapBounds[1][1] === undefined || gnssSample.longitude > this.mapBounds[1][1])
                            this.mapBounds[1][1] = gnssSample.longitude;
                    }

                    var gnssDate = new Date(gnssSample.utc);
                    var seconds = gnssDate.getSeconds();
                    if (seconds !== lastSecond)
                        points.push([gnssSample.latitude, gnssSample.longitude]);
                    lastSecond = seconds;

                    if (this.state.slider.hasRange && gnssSample.time >= this.state.slider.range[0] && gnssSample.time <= this.state.slider.range[1])
                        selectionPoints.push([gnssSample.latitude, gnssSample.longitude]);
                }

                pointList.push(points);
                selectedPointList.push(selectionPoints);
            }
        }

        var bounds = this.mapBounds;
        var center = bounds ? undefined : [52, 7];
        var zoom = bounds ? undefined : 4;

        function FitBounds() {
            const map = useMap();
            map.fitBounds(bounds);
        }

        function getPolyLines() {
            var result = [];
            var index = 0;
            for (const points of pointList) {
                if (points.length > 0)
                    result.push(<Polyline pathOptions={{ color: '#c0c0c0' }} positions={points} key={index++} />);
            }
            for (const selectionPoints of selectedPointList) {
                if (selectionPoints.length > 0)
                    result.push(<Polyline pathOptions={{ color: '#1c66a5' }} positions={selectionPoints} key={index++} />);
            }
            if (bounds && calcBounds) {
                result.push(<FitBounds key={index++} />);
            }
            return result;
        }

        return (
            <Box className='sub-box'>
                <MapContainer bounds={this.mapBounds} center={center} zoom={zoom} className='map-container'>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        maxZoom={22}
                        maxNativeZoom={19}
                    />
                    {getPolyLines()}
                </MapContainer>
            </Box>
        )
    }

    renderSegmentTable() {
        var rows = [];

        var measurement = this.state.measurement;
        var system = undefined;
        if (measurement?.systems)
            system = measurement.systems.find((item) => item.userID === this.state.systemID);

        var segments = system?.segments || [];
        for (var segmentInfo of segments) {
            var range = segmentInfo?.details?.range;
            if (!range)
                continue;
            rows.push({
                id: segmentInfo.date,
                userID: segmentInfo.userID,
                details: segmentInfo.details,
            });
        }

        const getCompareCheckbox = (source) => {
            var segmentCount = source.length;
            var compareCount = 0;

            var compareItems = this.getCompareItems();
            for (const row of source) {
                var compareItem = compareItems.find(item => item.measurementID === this.state.measurementID && item.systemID === this.state.systemID && item.segmentID === row.id);
                if (compareItem)
                    compareCount++;
            }

            const onCompareCheckbox = () => {
                var checked = segmentCount !== compareCount;
                var compareItems = this.getCompareItems();
                for (const row of source) {
                    var compareItem = compareItems.find(item => item.measurementID === this.state.measurementID && item.systemID === this.state.systemID && item.segmentID === row.id);
                    if (checked && !compareItem) {
                        compareItems.push({
                            measurementID: this.state.measurementID,
                            systemID: this.state.systemID,
                            horseID: system.horseID,
                            segmentID: row.id,
                            details: row.details,
                        });
                    } else if (!checked && compareItem) {
                        compareItems = compareItems.filter((item) => !(item.measurementID === this.state.measurementID && item.systemID === this.state.systemID && item.segmentID === row.id));
                    }
                }
                localStorage.setItem('compareItems', JSON.stringify(compareItems));
                this.setState({});
            };

            var compareHorseID = compareItems.length > 0 ? compareItems[0].horseID : undefined;
            var differentHorseID = system?.horseID && compareHorseID && system?.horseID !== compareHorseID;
            var tooltip = undefined;
            if (differentHorseID) {
                var horseName = this.state.horses.find((item) => item.horseID === compareHorseID)?.name;
                tooltip = 'Comparison is currently in use for ' + (horseName ? ('"' + horseName + '"') : 'an unknown horse');
            }

            const onClickCheckbox = (event) => {
                event.stopPropagation(); // don't select row when clicking checkbox
            }

            var onClearCompare = () => {
                this.setState({ clearConfirm: true });
            };

            return (
                <Tooltip disableHoverListener={!differentHorseID} title={
                    <Box className='flex-row' style={{ fontSize: '1rem', textAlign: 'center' }}>
                        {tooltip}
                        <Button variant='outlined' sx={{ margin: '0 auto 1rem auto' }} onClick={onClearCompare}>
                            Clear Comparison
                        </Button>
                    </Box>
                }>
                    <span>
                        <Checkbox
                            sx={{ paddingTop: 0, paddingBottom: 0 }}
                            disabled={differentHorseID}
                            checked={segmentCount === compareCount}
                            indeterminate={compareCount > 0 && compareCount < segmentCount}
                            onChange={onCompareCheckbox}
                            onClick={onClickCheckbox}
                        />
                    </span>
                </Tooltip>
            );
        }

        const columns = [
            {
                field: 'range',
                headerName: 'Segment',
                sortable: false,
                sortComparator: (value1, value2) => value1[0] - value2[0],
                width: 150,
                valueGetter: (value, row) => {
                    return row.details.range;
                },
                renderCell: (params) => {
                    var range = formatTime(params.value[0]) + ' - ' + formatTime(params.value[1]);
                    return <Box className='DataGrid-cell'>{range}</Box>;
                },
            },
            {
                field: 'duration',
                headerName: 'Duration',
                sortable: false,
                width: 100,
                valueGetter: (value, row) => {
                    return row.details.range;
                },
                renderCell: (params) => {
                    var duration = formatTime(params.value[1] - params.value[0]);
                    return <Box className='DataGrid-cell'>{duration}</Box>;
                },
            },
            {
                field: 'userID',
                headerName: 'Added By',
                sortable: false,
                width: 150,
                renderCell: (params) => {
                    var username = this.state.users.find((item) => item.userID === params.value)?.username || 'unknown';
                    return <Box className='DataGrid-cell'>{username}</Box>;
                },
            },
            {
                field: 'heartRate',
                headerName: 'Heart Rate (bpm)',
                sortable: false,
                width: 150,
                valueGetter: (value, row) => {
                    return row.details.heartRate;
                },
                renderCell: (params) => {
                    var heartRate = params.value;
                    if (!heartRate)
                        return;

                    const getMean = () => {
                        if (heartRate.mean == null)
                            return;
                        return <span>{heartRate.mean.toFixed(1)}</span>;
                    }

                    const getStdDev = () => {
                        if (!this.state.showStdDev || heartRate.stddev === null)
                            return;
                        return <span style={{ fontSize: '0.75rem', marginLeft: '0.5rem' }}>{'\u00B1' + heartRate.stddev.toFixed(2)}</span>;
                    }

                    const getMax = () => {
                        if (!this.state.showMaximum || heartRate.max === null)
                            return;
                        return <Box sx={{ marginTop: '0.5rem' }} >{'max: ' + heartRate.max.toFixed(0)}</Box>;
                    }

                    return (
                        <Box className='DataGrid-cell' sx={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                            <Box>
                                {getMean()}
                                {getStdDev()}
                            </Box>
                            {getMax()}
                        </Box>
                    );
                },
            },
            {
                field: 'symmetry',
                headerName: 'Symmetry',
                sortable: false,
                width: 150,
                valueGetter: (value, row) => {
                    return row.details.symmetry;
                },
                renderCell: (params) => {
                    var symmetry = params.value;
                    if (!symmetry)
                        return;

                    const getMean = () => {
                        if (symmetry.mean == null)
                            return;
                        return <span>{symmetry.mean.toFixed(2)}</span>;
                    }

                    const getStdDev = () => {
                        if (!this.state.showStdDev || symmetry.stddev === null)
                            return;
                        return <span style={{ fontSize: '0.75rem', marginLeft: '0.5rem' }}>{'\u00B1' + symmetry.stddev.toFixed(2)}</span>;
                    }

                    const getMax = () => {
                        if (!this.state.showMaximum || symmetry.max === null)
                            return;
                        return <Box sx={{ marginTop: '0.5rem' }} >{'max: ' + symmetry.max.toFixed(2)}</Box>;
                    }

                    return (
                        <Box className='DataGrid-cell' sx={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                            <Box>
                                {getMean()}
                                {getStdDev()}
                            </Box>
                            {getMax()}
                        </Box>
                    );
                },
            },
            {
                field: 'pace',
                headerName: 'Pace (min/km)',
                sortable: false,
                width: 150,
                valueGetter: (value, row) => {
                    return row.details.speed;
                },
                renderCell: (params) => {
                    var speed = params.value;
                    if (!speed)
                        return;

                    var kphToPace = (kph) => {
                        return 60 / kph;
                    }
                    var formatPace = (value) => {
                        var minute = Math.floor(value);
                        var second = Math.round((value - minute) * 60);
                        if (second >= 60) {
                            minute++;
                            second -= 60;
                        }
                        return minute + ':' + second.toFixed(0).padStart(2, '0');
                    }

                    const getMean = () => {
                        if (speed.mean == null)
                            return;
                        return <span>{formatPace(kphToPace(speed.mean))}</span>;
                    }

                    const getStdDev = () => {
                        if (!this.state.showStdDev || speed.stddev === null)
                            return;
                        return <span style={{ fontSize: '0.75rem', marginLeft: '0.5rem' }}>{'\u00B1' + formatPace(speed.stddev / speed.mean * kphToPace(speed.mean))}</span>;
                    }

                    const getMax = () => {
                        if (!this.state.showMaximum || speed.max === null)
                            return;
                        return <Box sx={{ marginTop: '0.5rem' }} >{'max: ' + formatPace(kphToPace(speed.max))}</Box>;
                    }

                    return (
                        <Box className='DataGrid-cell' sx={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                            <Box>
                                {getMean()}
                                {getStdDev()}
                            </Box>
                            {getMax()}
                        </Box>
                    );
                }
            },
            {
                field: 'speed',
                headerName: 'Speed (km/h)',
                sortable: false,
                width: 150,
                valueGetter: (value, row) => {
                    return row.details.speed;
                },
                renderCell: (params) => {
                    var speed = params.value;
                    if (!speed)
                        return;

                    const getMean = () => {
                        if (speed.mean == null)
                            return;
                        return <span>{speed.mean.toFixed(2)}</span>;
                    }

                    const getStdDev = () => {
                        if (!this.state.showStdDev || speed.stddev === null)
                            return;
                        return <span style={{ fontSize: '0.75rem', marginLeft: '0.5rem' }}>{'\u00B1' + speed.stddev.toFixed(2)}</span>;
                    }

                    const getMax = () => {
                        if (!this.state.showMaximum || speed.max === null)
                            return;
                        return <Box sx={{ marginTop: '0.5rem' }} >{'max: ' + speed.max.toFixed(2)}</Box>;
                    }

                    return (
                        <Box className='DataGrid-cell' sx={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                            <Box>
                                {getMean()}
                                {getStdDev()}
                            </Box>
                            {getMax()}
                        </Box>
                    );
                }
            },
            {
                field: 'quality',
                headerName: 'GNSS Quality',
                sortable: false,
                width: 110,
                valueGetter: (value, row) => {
                    return row.details.satellites;
                },
                renderCell: (params) => {
                    if (!params.value)
                        return;

                    var satellites = params.value.mean;
                    var quality = 'error';
                    if (satellites >= 8)
                        quality = 'success';
                    else if (satellites >= 4)
                        quality = 'warning';

                    const onGnssCheckbox = (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                    }

                    return (
                        <Box className='DataGrid-cell'>
                            <Tooltip title={satellites ? satellites.toFixed(0) : ''} arrow>
                                <Checkbox
                                    disableRipple
                                    color={quality}
                                    indeterminate={quality !== 'success'}
                                    checked={quality === 'success'}
                                    onChange={onGnssCheckbox}
                                    onClick={onGnssCheckbox}
                                    sx={{ padding: '0 0 0 0.5rem' }}
                                />
                            </Tooltip>
                        </Box>
                    );
                }
            },
            {
                field: 'length',
                headerName: 'Stride Length',
                sortable: false,
                width: 150,
                valueGetter: (value, row) => {
                    return row.details.strideLength;
                },
                renderCell: (params) => {
                    var length = params.value;
                    if (!length)
                        return;

                    const getMean = () => {
                        if (length.mean == null)
                            return;
                        return <span>{length.mean.toFixed(2)}</span>;
                    }

                    const getStdDev = () => {
                        if (!this.state.showStdDev || length.stddev === null)
                            return;
                        return <span style={{ fontSize: '0.75rem', marginLeft: '0.5rem' }}>{'\u00B1' + length.stddev.toFixed(2)}</span>;
                    }

                    const getMax = () => {
                        if (!this.state.showMaximum || length.max === null)
                            return;
                        return <Box sx={{ marginTop: '0.5rem' }} >{'max: ' + length.max.toFixed(2)}</Box>;
                    }

                    return (
                        <Box className='DataGrid-cell' sx={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                            <Box>
                                {getMean()}
                                {getStdDev()}
                            </Box>
                            {getMax()}
                        </Box>
                    );
                },
            },
            {
                field: 'frequency',
                headerName: 'Stride Frequency',
                sortable: false,
                width: 150,
                valueGetter: (value, row) => {
                    return row.details.strideFrequency;
                },
                renderCell: (params) => {
                    var frequency = params.value;
                    if (!frequency)
                        return;

                    const getMean = () => {
                        if (frequency.mean == null)
                            return;
                        return <span>{frequency.mean.toFixed(2)}</span>;
                    }

                    const getStdDev = () => {
                        if (!this.state.showStdDev || frequency.stddev === null)
                            return;
                        return <span style={{ fontSize: '0.75rem', marginLeft: '0.5rem' }}>{'\u00B1' + frequency.stddev.toFixed(2)}</span>;
                    }

                    const getMax = () => {
                        if (!this.state.showMaximum || frequency.max === null)
                            return;
                        return <Box sx={{ marginTop: '0.5rem' }} >{'max: ' + frequency.max.toFixed(2)}</Box>;
                    }

                    return (
                        <Box className='DataGrid-cell' sx={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                            <Box>
                                {getMean()}
                                {getStdDev()}
                            </Box>
                            {getMax()}
                        </Box>
                    );
                },
            },
            {
                field: 'notes',
                headerName: 'Notes',
                sortable: false,
                width: 250,
                valueGetter: (value, row) => {
                    return row.details.notes || '';
                },
                renderCell: (params) => {
                    const onEditSegmentNotes = () => {
                        this.setState({ notesSegmentID: params.row.id });
                    }

                    return (
                        <Box className='DataGrid-cell' sx={{ whiteSpace: 'pre-line' }}>
                            {params.value}
                            <IconButton
                                sx={{ alignSelf: 'end', }}
                                onClick={onEditSegmentNotes}
                                color={'primary'}
                            >
                                <EditIcon />
                            </IconButton>
                        </Box>
                    );
                },
            },
            {
                field: 'compare',
                headerName: 'Compare',
                sortable: false,
                width: 120,
                renderHeader: (params) => {
                    return (
                        <div>
                            {getCompareCheckbox(rows)}
                            <span style={{ verticalAlign: 'middle' }}>Compare</span>
                        </div>
                    );
                },
                renderCell: (params) => {
                    return (
                        <Box className='DataGrid-cell'>
                            {getCompareCheckbox([params.row])}
                        </Box>
                    );
                },
            },
            {
                field: 'actions',
                headerName: 'Actions',
                sortable: false,
                width: 140,
                renderCell: (params) => {
                    const onRemoveSegment = (event) => {
                        event.stopPropagation(); // don't select row when clicking remove button

                        var socket = getSocket();
                        socket.emit('measurements', 'request', 'removeSegment', {
                            measurementID: this.state.measurementID,
                            systemID: this.state.systemID,
                            date: params.row.id,
                        });

                        var selection = this.state.selection;
                        if (selection.id === params.row.id) {
                            selection.remove = true;
                            this.setState({ selection });
                        }
                    };
                    return (
                        <Box className='DataGrid-cell'>
                            <Button variant='outlined' focusRipple onClick={onRemoveSegment}>Remove</Button>
                        </Box>
                    );
                }
            },
        ];

        var getFooter = () => {
            const onCompareSegments = () => {
                this.setState({ navigate: '/compare' });
            };

            const onClearSegments = () => {
                var socket = getSocket();
                socket.emit('measurements', 'request', 'clearSegments', {
                    measurementID: this.state.measurementID,
                    systemID: this.state.systemID,
                });
            };

            const onShowStdDev = (event) => {
                localStorage.setItem('detailsShowStdDev', event.target.checked ? 1 : 0);
                this.setState({ showStdDev: event.target.checked });
            };

            const onShowMaximum = (event) => {
                localStorage.setItem('detailsShowMaximum', event.target.checked ? 1 : 0);
                this.setState({ showMaximum: event.target.checked });
            };

            return (
                <Box className='flex-row' margin='0.5rem 2rem 0.5rem auto'>
                    <FormControlLabel
                        label="standard deviation"
                        control={
                            <Checkbox
                                checked={this.state.showStdDev}
                                onChange={onShowStdDev}
                            />
                        }
                    />
                    <FormControlLabel
                        label="maximum"
                        control={
                            <Checkbox
                                checked={this.state.showMaximum}
                                onChange={onShowMaximum}
                            />
                        }
                    />
                    <Button variant='contained' onClick={onCompareSegments} >Compare</Button>
                    <Button variant='outlined' onClick={onClearSegments} >Clear Segments</Button>
                </Box>
            );
        };

        const onRowClick = (params) => {
            var slider = this.state.slider;
            slider.range = params.row.details.range;

            var selection = this.state.selection;
            selection.id = params.row.id;

            this.setState({ slider, selection });

            this.resetChartZoom();
        };

        // change selection to added row, remove selection from removed row
        var selection = this.state.selection;
        if (selection.received) {
            if (selection.add)
                selection.id = rows.length > 0 ? rows[rows.length - 1].id : 0;
            if (selection.remove)
                selection.id = 0;
        }
        if (selection.received) {
            selection.received = false;
            selection.add = false;
            selection.remove = false;
            setTimeout(() => {
                this.setState({ selection });
            }, 0);
        }

        const handleClearConfirmClose = () => {
            this.setState({ clearConfirm: false });
        };

        const onClearConfirm = () => {
            localStorage.setItem('compareItems', JSON.stringify([]));
            handleClearConfirmClose();
        };

        const onSaveNotes = (event) => {
            event.preventDefault();
            var notes = '';
            const formData = new FormData(event?.currentTarget);
            if (formData)
                notes = Object.fromEntries(formData.entries()).notes;

            var socket = getSocket();
            socket.emit('measurements', 'request', 'addSegment', {
                measurementID: this.state.measurementID,
                systemID: this.state.systemID,
                segment: {
                    date: this.state.notesSegmentID,
                    notes: notes,
                },
            });

            handleNotesClose();
        };

        const handleNotesClose = () => {
            this.setState({ notesSegmentID: undefined });
        };

        var noteText = '';
        if (this.state.notesSegmentID !== undefined) {
            var segment = system.segments.find((item) => item.date === this.state.notesSegmentID);
            noteText = segment.details.notes || '';
        }

        // possible work-around for 'Blocked aria-hidden on a <div> element ...' warning when datagrid gets a horizontal scrollbar
        // setTimeout(() => {
        //     var scrollbars = document.getElementsByClassName('MuiDataGrid-scrollbar--horizontal');
        //     for (var scrollbar of scrollbars) {
        //         scrollbar.setAttribute("aria-hidden", false);
        //     }
        // }, 100);

        return (
            <Box className='sub-box'>
                <Dialog
                    open={this.state.clearConfirm === true}
                    onClose={handleClearConfirmClose}
                >
                    <DialogContent>
                        {'Are you sure you want to clear all comparison segments?\n'}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClearConfirmClose}>Cancel</Button>
                        <Button onClick={onClearConfirm}>Confirm</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.notesSegmentID !== undefined}
                    onClose={handleNotesClose}
                    fullWidth={true}
                    maxWidth={'sm'}
                    PaperProps={{
                        component: 'form',
                        onSubmit: onSaveNotes,
                    }}
                >
                    <DialogContent>
                        <TextField
                            autoFocus
                            fullWidth
                            margin='normal'
                            multiline
                            rows={3}
                            label='Notes'
                            name='notes'
                            defaultValue={noteText}
                            onFocus={event => event.target.selectionStart = event.target.selectionEnd = event.target.value.length}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleNotesClose}>Cancel</Button>
                        <Button type='submit'>Save</Button>
                    </DialogActions>
                </Dialog>

                <DataGrid
                    rows={rows}
                    columns={columns}
                    disableColumnMenu
                    hideFooterSelectedRowCount
                    autoHeight={true}
                    getRowHeight={() => 'auto'}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                page: 0,
                                pageSize: 100,
                            },
                        },
                        sorting: {
                            sortModel: [{
                                field: 'range',
                                sort: 'asc',
                            }],
                        },
                    }}
                    pageSizeOptions={[100]}
                    slots={{
                        footer: getFooter,
                    }}
                    onRowClick={onRowClick}
                    rowSelectionModel={selection.id}
                />
            </Box>
        );
    }

    renderComments() {
        var measurement = this.state.measurement;
        var system = undefined;
        if (measurement?.systems)
            system = measurement.systems.find((item) => item.userID === this.state.systemID);
        if (!system)
            return;

        var elements = [];
        var listItemIndex = 0;

        for (const comment of system.comments) {
            if (elements.length > 0)
                elements.push(
                    <Divider
                        key={listItemIndex++}
                        variant='inset'
                        component='li'
                    />
                );

            elements.push(
                <ListItem key={listItemIndex++} >
                    <ListItemAvatar>
                        <Avatar>
                            <CommentIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{ whiteSpace: 'pre-line' }}
                        primary={
                            <Stack
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 2,
                                }}
                            >
                                <Box color='text.primary'>
                                    {this.state.users.find((item) => item.userID === comment.userID)?.username || 'unknown'}
                                </Box>
                                <Box sx={{ marginLeft: 'auto' }}>
                                    {new Date(comment.date).toLocaleString()}
                                </Box>
                            </Stack>
                        }
                        secondary={comment.comment}
                    />
                </ListItem>
            );
        }

        if (this.lastCommentCount !== system.comments.length) {
            if (this.lastCommentCount !== undefined) {
                setTimeout(() => {
                    const commentBox = document.getElementById('measurement-notes');
                    commentBox.scrollIntoView({ behavior: 'smooth' });
                }, 100);
            }
            this.lastCommentCount = system.comments.length;
        }

        const onSaveMeasurementNotes = () => {
            if (!this.state.comment)
                return;

            var socket = getSocket();
            socket.emit('measurements', 'request', 'addComment', {
                measurementID: this.state.measurementID,
                systemID: this.state.systemID,
                comment: this.state.comment,
            });
            this.setState({
                commentSaveIconColor: 'warning',
            });
        }

        elements.push(
            <ListItem key={listItemIndex++} >
                <TextField
                    sx={{ width: '100%' }}
                    id='measurement-notes'
                    label='Add notes'
                    multiline
                    rows={2}
                    value={this.state.comment}
                    onChange={e => this.setState({ comment: e.target.value })}
                    InputProps={{
                        endAdornment:
                            <IconButton
                                sx={{ alignSelf: 'end', }}
                                onClick={onSaveMeasurementNotes}
                                color={this.state.commentSaveIconColor}
                            >
                                <SaveIcon />
                            </IconButton>
                    }}
                />
            </ListItem>
        );

        return (
            <Box className='sub-box' sx={{ width: '100%', height: infoHeight, overflow: 'auto' }}>
                <List sx={{ width: '100%', padding: 0, }} >
                    {elements}
                </List>
            </Box>
        );
    }

    renderMeasurementInfo() {
        var measurement = this.state.measurement;
        var system = undefined;
        if (measurement?.systems)
            system = measurement.systems.find((item) => item.userID === this.state.systemID);
        if (!system)
            return;

        const onReload = async () => {
            await removeFile(this.state.measurementID, this.state.systemID);
            this.clearCharts();
            this.loadMeasurement();
        };

        const onDownload = (fileType) => {
            var measurementID = measurement.measurementID;
            var systemID = system.userID;
            this.downloadFile({ measurementID, systemID, fileType });
        };

        var date = (new Date(measurement.date)).toLocaleString();
        var boxName = this.state.users.find((item) => item.userID === system.userID)?.username || 'unknown';
        var creator = this.state.users.find((item) => item.userID === measurement.userID)?.username || 'unknown';

        var horse = this.state.horses.find((item) => item.horseID === system.horseID);
        var horseName = horse?.name || '';
        var horseDetails = '';
        if (horse?.stables || horse?.owner) {
            if (horse?.stables)
                horseDetails += horse.stables + (horse.owner ? ', ' : '');
            if (horse?.owner)
                horseDetails += horse.owner;
        }

        var trainingTypeName = this.state.trainingTypes.find((item) => item.trainingTypeID === system.trainingTypeID)?.name || '';
        var duration = system.duration > 0 ? formatTime(system.duration) : '';

        const getDownloadButtons = () => {
            var elements = [];
            for (const key of ['inertia', 'polar', 'results']) {
                var value = system?.files ? system.files[key]?.filename : undefined;
                elements.push(
                    <Tooltip title={key} key={key} arrow>
                        <span>
                            <IconButton sx={{ padding: 0, marginRight: '8px' }} disabled={value ? false : true} onClick={() => onDownload(key)} >
                                <DownloadIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                );
            }
            return elements;
        }

        const onEditHorse = () => {
            this.setState({
                changeHorseID: system.horseID,
                changeTrainingTypeID: system.trainingTypeID,
            });
        }

        var col1width = '200px';
        var col2width = '300px';

        return (
            <div className='sub-box flex-col my-cardGrid' style={{
                width: '100%',
                height: infoHeight,
                overflow: 'auto',
                gridTemplateColumns: 'repeat(auto-fit, minmax(min(260px, 95%), 1fr))',
                gap: 0,
                paddingLeft: 0,
                paddingRight: 0,
            }}>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr key={1}>
                            <td className='text-right' style={{ width: col1width, paddingRight: '1rem', alignContent: 'start' }}>Horse:</td>
                            <td style={{ width: col2width }}>
                                <Box className='flex-row' sx={{ gap: 0 }}>
                                    <Box>
                                        <Box color='text.primary' sx={{ fontSize: '1rem' }} >{horseName}</Box>
                                        <Box color='text.secondary' sx={{ fontSize: '0.75rem' }}>{horseDetails}</Box>
                                        <Box color='text.secondary' sx={{ fontSize: '0.75rem' }}>{boxName}</Box>
                                    </Box>
                                    <IconButton
                                        sx={{ alignSelf: 'end', marginLeft: 'auto', marginRight: '1rem' }}
                                        onClick={onEditHorse}
                                        color={'primary'}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Box>
                            </td>
                        </tr>
                        <tr key={2}>
                            <td className='text-right' style={{ width: col1width, paddingRight: '1rem' }}>Created:</td>
                            <td style={{ width: col2width }}>
                                <Tooltip title={measurement.measurementID} arrow>
                                    {date}
                                </Tooltip>
                            </td>
                        </tr>
                        <tr key={3}>
                            <td className='text-right' style={{ width: col1width, paddingRight: '1rem' }}>By:</td>
                            <td style={{ width: col2width }}>{creator}</td>
                        </tr>
                        <tr key={4}>
                            <td className='text-right' style={{ width: col1width, paddingRight: '1rem' }}>Training Type:</td>
                            <td style={{ width: col2width }}>{trainingTypeName}</td>
                        </tr>
                        <tr key={5}>
                            <td className='text-right' style={{ width: col1width, paddingRight: '1rem' }}>Duration:</td>
                            <td style={{ width: col2width }}>{duration}</td>
                        </tr>
                    </tbody>
                </table>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr key={1}>
                            <td className='text-right' style={{ width: col1width, paddingRight: '1rem' }}>Files:</td>
                            <td style={{ width: col2width }}>
                                <Box>
                                    {getDownloadButtons()}
                                </Box>
                            </td>
                        </tr>
                        <tr key={2}>
                            <td className='text-right' style={{ width: col1width, paddingRight: '1rem' }}>State:</td>
                            <td style={{ width: col2width }}>
                                <div style={{ display: 'flex' }}>
                                    <span style={{ alignSelf: 'center' }}>{this.getMeasurementStateDescription()}</span>
                                    <IconButton sx={{ padding: 0, marginLeft: '8px' }} onClick={onReload}>
                                        <CachedIcon />
                                    </IconButton>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderHorseChange() {
        if (this.state.changeHorseID === undefined && this.state.changeTrainingTypeID === undefined)
            return;

        const onSelectHorse = (event) => {
            this.setState({ changeHorseID: event?.target?.value });
        };

        const onSelectTraining = (event) => {
            this.setState({ changeTrainingTypeID: event?.target?.value });
        };

        const onCancelMeasurementChanges = () => {
            this.setState({
                changeHorseID: undefined,
                changeTrainingTypeID: undefined,
            });
        };

        const onApplyMeasurementChanges = (event) => {
            event.preventDefault();

            var socket = getSocket();
            socket.emit('measurements', 'request', 'set', {
                measurementID: this.state.measurementID,
                systemID: this.state.systemID,
                horseID: this.state.changeHorseID === '' ? null : this.state.changeHorseID,
                trainingTypeID: this.state.changeTrainingTypeID === '' ? null : this.state.changeTrainingTypeID,
            });

            onCancelMeasurementChanges();
        };

        const getHorses = () => {
            const renderedItems = [];
            renderedItems.push(
                <MenuItem key={'empty'} value={''}><em>none</em></MenuItem>
            );
            for (const horse of this.state.horses) {
                renderedItems.push(
                    <MenuItem key={horse.horseID} value={horse.horseID}>{horse.name}</MenuItem>
                );
            }
            renderedItems.sort((a, b) => {
                var name1 = a.key === 'empty' ? '' : a.props.children;
                var name2 = b.key === 'empty' ? '' : b.props.children;
                return name1.localeCompare(name2);
            });
            return renderedItems;
        };

        const getTrainingTypes = () => {
            const renderedItems = [];
            renderedItems.push(
                <MenuItem key={'empty'} value={''}><em>none</em></MenuItem>
            );
            for (const trainingType of this.state.trainingTypes) {
                renderedItems.push(
                    <MenuItem key={trainingType.trainingTypeID} value={trainingType.trainingTypeID}>{trainingType.name}</MenuItem>
                );
            }
            renderedItems.sort((a, b) => {
                var name1 = a.key === 'empty' ? '' : a.props.children;
                var name2 = b.key === 'empty' ? '' : b.props.children;
                return name1.localeCompare(name2);
            });
            return renderedItems;
        };

        return (
            <Dialog
                open={this.state.changeHorseID !== undefined || this.state.changeTrainingTypeID !== undefined}
                onClose={onCancelMeasurementChanges}
            >
                <DialogContent>
                    <Box className='flex-col'>
                        <FormControl fullWidth sx={{ width: 250 }}>
                            <InputLabel id='horse-label'>Horse name</InputLabel>
                            <Select
                                labelId='horse-label'
                                id='horse-label'
                                value={this.state.changeHorseID || ''}
                                label='Horse name'
                                onChange={onSelectHorse}
                            >
                                {getHorses()}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ width: 250 }}>
                            <InputLabel id='training-label'>Training type</InputLabel>
                            <Select
                                labelId='training-label'
                                id='training-label'
                                value={this.state.changeTrainingTypeID || ''}
                                label='Training type'
                                onChange={onSelectTraining}
                            >
                                {getTrainingTypes()}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelMeasurementChanges}>Cancel</Button>
                    <Button onClick={onApplyMeasurementChanges}>{'Change'}</Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderDetails() {
        if (!this.state.measurementID || !this.state.systemID)
            return;

        this.checkMeasurement();

        return (
            <Box sx={{ width: '100%' }}>

                {this.renderHorseChange()}

                <div className='details-card-grid'>
                    {this.renderMeasurementInfo()}
                    {this.renderComments()}
                </div>
                <div className='details-card-grid'>
                    {this.renderCharts()}
                    {this.renderMap()}
                </div>
                {this.renderSegmentTable()}
            </Box>
        );
    }

    getConnectedStyle(available) {
        return {
            color: available ? undefined : 'red',
            fontWeight: available ? undefined : 'bold',
        };
    }

    getServerStatus() {
        var connected = this.state.isConnected === true;
        if (connected)
            return;

        return (
            <Box>
                {'Server: '}
                <span style={this.getConnectedStyle(connected)}>
                    {(connected ? 'online' : 'offline')}
                </span>
            </Box>
        )
    }

    render() {
        if (this.state.navigate !== undefined) {
            return (
                <Navigate to={this.state.navigate} />
            )
        }

        if (!this.props.loggedIn)
            return;

        const onBack = () => {
            this.setState({ navigate: '/measurements' });
        };

        const toggleDrawer = (newOpen) => () => {
            this.setState({ drawerOpen: newOpen });
        };

        const onDrawerClick = (event) => {
            var dest = getNavigation(event.target.innerText, window.location.pathname);
            this.setState({
                drawerOpen: false,
                navigate: dest
            });
        };

        const onToggleFootfalls = (event) => {
            localStorage.setItem('detailsShowFootfalls', event.target.checked ? 1 : 0);
            this.setState({ showFootfalls: event.target.checked });
        };

        return (
            <Box sx={{ padding: '0.5rem' }}>
                <ThemeProvider theme={this.defaultTheme}>
                    <CssBaseline />
                    <Drawer open={this.state.drawerOpen} onClose={toggleDrawer(false)}>
                        <Box sx={{ width: 250 }} role='presentation'>
                            <List>
                                {getPages().map((text) => (
                                    <ListItem key={text} disablePadding>
                                        <ListItemButton onClick={onDrawerClick}>
                                            <ListItemText primary={text} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                                <Divider component='li' />
                                <ListItem key={'enable-footfalls'}>
                                    <FormControlLabel
                                        label="Show footfall chart"
                                        control={
                                            <Checkbox
                                                checked={this.state.showFootfalls}
                                                onChange={onToggleFootfalls}
                                            />
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Box>
                    </Drawer>
                    <Box>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '20%' }} className='text-left'>
                                        <Button onClick={toggleDrawer(true)} startIcon={<MenuIcon />}>Menu</Button>
                                        <Button onClick={onBack} sx={{ marginLeft: '1rem' }} startIcon={<ArrowBackRoundedIcon />} component='a'>Measurements</Button>
                                    </td>
                                    <td style={{ width: '60%' }} className='text-center'><Box sx={{ color: 'primary.main', fontWeight: 'bold', fontSize: '1.5rem' }}>Details</Box></td>
                                    <td style={{ width: '20%' }} className='text-right'>{this.getServerStatus()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>
                    {this.renderDetails()}
                </ThemeProvider >
            </Box>
        )
    }
}

export default Details;
