import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { getSocket, socketConnected } from './Socket';
import { getPages, getNavigation } from './App';
import { Box, Button, Checkbox, CssBaseline, Dialog, DialogActions, DialogContent, Divider, Drawer, FormControlLabel, Link, List, ListItem, ListItemButton, ListItemText, Stack, TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Menu as MenuIcon,
} from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';


class Horses extends Component {
    constructor(props) {
        super(props);

        this.defaultTheme = createTheme({ palette: { mode: 'dark' } });

        // allow to use 'this' to access class members
        this.connectHandler = this.connectHandler.bind(this);
        this.userHandler = this.userHandler.bind(this);
        this.horseHandler = this.horseHandler.bind(this);

        this.state = {
            isConnected: socketConnected(),
            navigate: undefined,
            drawerOpen: false,
            users: [],
            horses: [],
            editHorseID: undefined,
            removeItem: undefined,
            showRemovedHorses: parseInt(localStorage.getItem('horsesShowRemoved')) ? true : false,
        }
    }

    componentDidMount() {
        var socket = getSocket();
        if (socket) {
            socket.on('connect', this.connectHandler);
            socket.on('disconnect', this.connectHandler);
            socket.on('users', this.userHandler);
            socket.on('horses', this.horseHandler);

            this.getUsers();
            this.getHorses();
        }
    }

    componentWillUnmount() {
        var socket = getSocket();
        if (socket) {
            socket.off('connect', this.connectHandler);
            socket.off('disconnect', this.connectHandler);
            socket.off('users', this.userHandler);
            socket.off('horses', this.horseHandler);
        }
    }

    getUsers() {
        var socket = getSocket();
        socket.emit('users', 'request', 'get');
    }

    getHorses() {
        var socket = getSocket();
        socket.emit('horses', 'request', 'get', { includeRemoved: true });
    }

    connectHandler(reason) {
        this.setState({ isConnected: socketConnected() });

        if (reason === 'io server disconnect')
            this.getUsers();
    }

    userHandler(channel, subChannel, data) {
        if (channel === 'response') {
            if (subChannel === 'get') {
                this.setState({ users: data });
            }
        }
    }

    horseHandler(channel, subChannel, data) {
        if (channel === 'response') {
            if (subChannel === 'get') {
                this.setState({ horses: data });
            }
            else {
                this.getHorses();
            }
        }
    }

    renderHorsesTable() {
        const createFilterLink = (text) => {
            const onClickItem = (event) => {
                event.preventDefault();
                localStorage.setItem('measurementHorseFilter', JSON.stringify([text]));
                this.setState({ navigate: '/measurements' });
            };

            return (
                <Box className='DataGrid-cell'>
                    <Link
                        underline='hover'
                        href='#'
                        onClick={onClickItem}
                    >
                        {text}
                    </Link>
                </Box>
            );
        }

        const columns = [
            {
                field: 'name',
                headerName: 'Name',
                sortable: true,
                width: 200,
                valueGetter: (value, row) => {
                    return row.horse.name;
                },
                renderCell: (params) => {
                    var name = params.row.horse.name;
                    return createFilterLink(name);
                },
            },
            {
                field: 'stables',
                headerName: 'Stables',
                sortable: false,
                width: 200,
                renderCell: (params) => {
                    var stables = params.row.horse.stables || '';
                    return createFilterLink(stables);
                }
            },
            {
                field: 'owner',
                headerName: 'Owner',
                sortable: false,
                width: 200,
                renderCell: (params) => {
                    var owner = params.row.horse.owner || '';
                    return createFilterLink(owner);
                }
            },
            {
                field: 'user',
                headerName: 'Added By',
                sortable: false,
                width: 120,
                renderCell: (params) => {
                    var userID = params.row.horse.userID;
                    var username = this.state.users.find((item) => item.userID === userID)?.username || '';
                    return <Box className='DataGrid-cell'>{username}</Box>;
                }
            },
            {
                field: 'system',
                headerName: 'Assigned To',
                sortable: false,
                width: 120,
                renderCell: (params) => {
                    var systemID = params.row.horse.systemID;
                    var username = this.state.users.find((item) => item.userID === systemID)?.username || '';
                    return <Box className='DataGrid-cell'>{username}</Box>;
                }
            },
            {
                field: 'actions',
                headerName: 'Actions',
                sortable: false,
                width: 200,
                renderCell: (params) => {
                    const onEditHorse = () => {
                        this.setState({ editHorseID: params.id });
                    };

                    var isRemoved = params.row.horse.removed;

                    const onRemoveHorse = () => {
                        this.setState({
                            removeItem: {
                                horseID: params.id,
                                removed: isRemoved,
                            },
                        });
                    };

                    return (
                        <Box className='DataGrid-cell'>
                            <Stack
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 2,
                                    alignItems: 'center'
                                }}
                            >
                                <Button focusRipple variant='outlined' onClick={onEditHorse}>Edit</Button>
                                <Button focusRipple variant='outlined' onClick={onRemoveHorse}>{isRemoved ? 'Unhide' : 'Hide'}</Button>
                            </Stack>
                        </Box>
                    );
                }
            },
        ];

        var rows = [];
        for (const horse of this.state.horses) {
            if (horse.removed && !this.state.showRemovedHorses)
                continue;
            rows.push({
                id: horse.horseID,
                horse: horse,
            });
        }

        const setPaginationModel = (data) => {
            if (data) {
                localStorage.setItem('horsesPageSize', data.pageSize);
            }
        };

        const setSortModel = (data) => {
            if (data && data.length > 0) {
                localStorage.setItem('horsesSortField', data[0].field);
                localStorage.setItem('horsesSortDirection', data[0].sort);
            }
        };

        var addButtonToolbar = () => {
            const onAddHorse = (_e) => {
                this.setState({ editHorseID: null });
            };

            return (
                <Box className='flex-row'>
                    <Button
                        focusRipple
                        variant='contained'
                        sx={{ margin: '1rem auto 1rem 2rem' }}
                        onClick={onAddHorse}
                    >
                        Add
                    </Button>
                </Box>
            );
        };

        const handleAddClose = () => {
            this.setState({ editHorseID: undefined });
        };

        const handleRemoveClose = () => {
            this.setState({ removeItem: undefined });
        };

        const getHorseInfo = (horseID) => {
            return this.state.horses.find((item) => item.horseID === horseID);
        };

        const getButtonLabel = () => {
            return this.state.editHorseID ? 'Edit' : 'Add';
        }

        const onHorseAdd = (event) => {
            event.preventDefault();

            var name = '';
            var stables = '';
            var owner = '';
            const formData = new FormData(event?.currentTarget);
            if (formData) {
                var entries = Object.fromEntries(formData.entries());
                name = entries.name;
                stables = entries.stables;
                owner = entries.owner;
            }

            var socket = getSocket();
            socket.emit('horses', 'request', 'set', {
                horseID: this.state.editHorseID || undefined,
                name: name || undefined,
                stables: stables || null,
                owner: owner || null,
            });
            handleAddClose();
        };

        const onHorseRemove = (event) => {
            event.preventDefault();

            var socket = getSocket();
            socket.emit('horses', 'request', 'remove', {
                horseID: this.state.removeItem.horseID,
                removed: !this.state.removeItem.removed,
            });
            handleRemoveClose();
        };

        var pageSizeOptions = [5, 10, 25, 50, 100];
        var pageSize = parseInt(localStorage.getItem('horsesPageSize')) || 10;
        if (!pageSizeOptions.includes(pageSize))
            pageSize = pageSizeOptions[1];

        return (
            <Box sx={{ width: '100%' }}>
                <Dialog
                    open={this.state.editHorseID !== undefined}
                    onClose={handleAddClose}
                    PaperProps={{
                        component: 'form',
                        onSubmit: onHorseAdd,
                    }}
                >
                    <DialogContent>
                        <TextField
                            autoFocus
                            fullWidth
                            margin='normal'
                            label='Horse Name'
                            name='name'
                            defaultValue={(getHorseInfo(this.state.editHorseID)?.name || '')}
                        />
                        <TextField
                            fullWidth
                            margin='normal'
                            label='Stables'
                            name='stables'
                            defaultValue={(getHorseInfo(this.state.editHorseID)?.stables || '')}
                        />
                        <TextField
                            fullWidth
                            margin='normal'
                            label='Owner'
                            name='owner'
                            defaultValue={(getHorseInfo(this.state.editHorseID)?.owner || '')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAddClose}>Cancel</Button>
                        <Button type='submit'>{getButtonLabel()}</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.removeItem !== undefined}
                    onClose={handleRemoveClose}
                >
                    <DialogContent>
                        {'Are you sure you want to ' + (this.state.removeItem?.removed ? 'unhide' : 'hide') + ' "' + (getHorseInfo(this.state.removeItem?.horseID)?.name || '') + '"?'}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleRemoveClose}>Cancel</Button>
                        <Button onClick={onHorseRemove}>{this.state.removeItem?.removed ? 'Unhide' : 'Hide'}</Button>
                    </DialogActions>
                </Dialog>

                <DataGrid
                    rows={rows}
                    columns={columns}
                    disableColumnMenu
                    hideFooterSelectedRowCount
                    sortingOrder={['desc', 'asc']}
                    autoHeight={true}
                    getRowHeight={() => 'auto'}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                page: 0,
                                pageSize: pageSize,
                            },
                        },
                        sorting: {
                            sortModel: [{
                                field: localStorage.getItem('horsesSortField') || 'name',
                                sort: localStorage.getItem('horsesSortDirection') || 'desc',
                            }],
                        },
                    }}
                    pageSizeOptions={pageSizeOptions}
                    onPaginationModelChange={setPaginationModel}
                    onSortModelChange={setSortModel}
                    slots={{
                        toolbar: addButtonToolbar,
                    }}
                />
            </Box>
        );
    }

    getConnectedStyle(available) {
        return {
            color: available ? undefined : 'red',
            fontWeight: available ? undefined : 'bold',
        };
    }

    getServerStatus() {
        var connected = this.state.isConnected === true;
        if (connected)
            return;

        return (
            <Box>
                {'Server: '}
                <span style={this.getConnectedStyle(connected)}>
                    {(connected ? 'online' : 'offline')}
                </span>
            </Box>
        )
    }

    render() {
        if (this.state.navigate !== undefined) {
            return (
                <Navigate to={this.state.navigate} />
            )
        }

        if (!this.props.loggedIn)
            return;

        const toggleDrawer = (newOpen) => () => {
            this.setState({ drawerOpen: newOpen });
        };

        const onDrawerClick = (event) => {
            var dest = getNavigation(event.target.innerText, window.location.pathname);
            this.setState({
                drawerOpen: false,
                navigate: dest
            });
        };

        const onToggleShowRemovedHorses = (event) => {
            localStorage.setItem('horsesShowRemoved', event.target.checked ? 1 : 0);
            this.setState({ showRemovedHorses: event.target.checked });
        };

        return (
            <Box sx={{ padding: '0.5rem' }}>
                <ThemeProvider theme={this.defaultTheme}>
                    <CssBaseline />
                    <Drawer open={this.state.drawerOpen} onClose={toggleDrawer(false)}>
                        <Box sx={{ width: 250 }} role='presentation'>
                            <List>
                                {getPages().map((text) => (
                                    <ListItem key={text} disablePadding>
                                        <ListItemButton onClick={onDrawerClick}>
                                            <ListItemText primary={text} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                                <Divider component='li' />
                                <ListItem key={'show-horses'}>
                                    <FormControlLabel
                                        label="Show hidden horses"
                                        control={
                                            <Checkbox
                                                checked={this.state.showRemovedHorses}
                                                onChange={onToggleShowRemovedHorses}
                                            />
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Box>
                    </Drawer>
                    <Box>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '20%' }} className='text-left'><Button onClick={toggleDrawer(true)} startIcon={<MenuIcon />}>Menu</Button></td>
                                    <td style={{ width: '60%' }} className='text-center'><Box sx={{ color: 'primary.main', fontWeight: 'bold', fontSize: '1.5rem' }}>Horses</Box></td>
                                    <td style={{ width: '20%' }} className='text-right'>{this.getServerStatus()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>
                    {this.renderHorsesTable()}
                </ThemeProvider >
            </Box>

        )
    }
}

export default Horses;
