import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { getSocket, socketConnected, getSymOrder } from './Socket';
import { getHRcolor } from './Chart';
import { getPages, getNavigation } from './App';
import { Box, Button, ButtonGroup, CssBaseline, Checkbox, Dialog, DialogActions, DialogContent, Divider, Drawer, FormGroup, FormControl, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, Select } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    BatteryStdOutlined as BatteryStdOutlinedIcon,
    BatteryChargingFull as BatteryChargingFullIcon,
    BatteryAlert as BatteryAlertIcon,
    SettingsInputAntenna as SettingsInputAntennaIcon,
    UsbOff as UsbOffIcon,
    SatelliteAlt as SatelliteAltIcon,
    TimerOutlined as TimerOutlinedIcon,
    Add as AddIcon,
    Remove as RemoveIcon,
    NotInterested as NotInterestedIcon,
    Menu as MenuIcon,
} from '@mui/icons-material';


const loggingButtonTimeout = 500;

const systemType = {
    measuring: 2,
    online: 1,
    all: 0,
};

const defaultSystemInfo = {
    available: false,
    room: null,
    userID: null,

    heartRate: null,
    symmetry: null,

    loggingDuration: null,
    loggingType: '', // '', 'pre-hr', 'normal', 'post-hr'

    polarStatus: Object(),
    inertiaStatus: Object(),
    inertiaSensors: Object(),
    nodePositions: Object(),
    piJuice: Object(),
};


class Overview extends Component {
    constructor(props) {
        super(props);

        this.defaultTheme = createTheme({ palette: { mode: 'dark' } });

        // allow to use 'this' to access class members
        this.connectHandler = this.connectHandler.bind(this);
        this.dataHandler = this.dataHandler.bind(this);
        this.roomHandler = this.roomHandler.bind(this);
        this.userHandler = this.userHandler.bind(this);
        this.horseHandler = this.horseHandler.bind(this);

        this.loggingTimerID = undefined;

        var checkState = [];
        try {
            checkState = JSON.parse(localStorage.getItem('systemCheckboxes'));
        } catch (e) {
        }
        this.checkboxes = checkState || [];

        this.state = {
            isConnected: socketConnected(),
            navigate: undefined,
            drawerOpen: false,
            stopConfirm: false,
            systems: [],
            users: [],
            horses: [],
            overviewSystemType: parseInt(localStorage.getItem('overviewSystemType')),
            gridZoom: parseInt(localStorage.getItem('gridZoom') || 0),
            wakeLock: 'unknown',
            loggingButtonClickTime: 0,
        }
        if (isNaN(this.state.overviewSystemType))
            this.state.overviewSystemType = systemType.measuring;
    }

    componentDidMount() {
        var socket = getSocket();
        if (socket) {
            socket.on('connect', this.connectHandler);
            socket.on('disconnect', this.connectHandler);
            socket.on('data', this.dataHandler);
            socket.on('room', this.roomHandler);
            socket.on('users', this.userHandler);
            socket.on('horses', this.horseHandler);

            this.getUsers();
            this.getHorses();
            this.onGetRPis();

            this.setWakeLock();
        }

        this.loggingTimerID = setInterval(() => {
            this.updateLoggingTimer();
        }, 1000);
    }

    componentWillUnmount() {
        var socket = getSocket();
        if (socket) {
            for (const system of this.state.systems) {
                socket.emit('room', 'request', 'leave', system.room);
            }
            socket.off('connect', this.connectHandler);
            socket.off('disconnect', this.connectHandler);
            socket.off('data', this.dataHandler);
            socket.off('room', this.roomHandler);
            socket.off('users', this.userHandler);
            socket.off('horses', this.horseHandler);
        }

        clearInterval(this.loggingTimerID);
        this.loggingTimerID = undefined;
    }

    async setWakeLock() {
        let wakeLock = null;
        const acquireLock = async () => {
            if (!navigator.wakeLock) {
                console.warn('Wake Lock not available');
                this.setState({ wakeLock: 'unavailable' });
                return;
            }
            try {
                wakeLock = await navigator.wakeLock.request();
                this.setState({ wakeLock: 'requested' });
                wakeLock.addEventListener('release', () => {
                    this.setState({ wakeLock: 'released' });
                    wakeLock = null;
                });
            } catch (err) {
                // The Wake Lock request has failed - usually system related, such as battery.
                this.setState({ wakeLock: 'error' });
                console.error(err.name + ': ' + err.message);
            }
        };

        await acquireLock();

        document.addEventListener('visibilitychange', async () => {
            if (wakeLock === null && document.visibilityState === 'visible') {
                await acquireLock();
            }
        });
    }

    getUsers() {
        var socket = getSocket();
        socket.emit('users', 'request', 'get');
    }

    getHorses() {
        var socket = getSocket();
        socket.emit('horses', 'request', 'get');
    }

    connectHandler(reason) {
        this.setState({ isConnected: socketConnected() });

        if (reason === 'io server disconnect')
            this.getUsers();

        if (socketConnected())
            this.onGetRPis();
    }

    dataHandler(room, channel, subChannel, data) {
        var systems = this.state.systems;
        var system = systems.find((item) => item.room === room);
        if (!system)
            return;

        var changed = false;
        if (channel === 'inertia') {
            if (subChannel === 'status') {
                system.inertiaStatus = data;
                if (!system.inertiaStatus.connected) {
                    system.symmetry = null;
                    system.inertiaSensors = Object();
                }
                changed = true;
                this.updateLoggingTimer();
            }
            else if (subChannel === 'nodeData') {
                for (const [key, value] of Object.entries(data)) {
                    if (!system.inertiaSensors[key])
                        system.inertiaSensors[key] = Object();

                    if (!isNaN(value.bat) && value.bat != null) {
                        system.inertiaSensors[key].bat = value.bat;
                    }

                    if (this.useForSymmetry(system, key)) {
                        if (!value.symreg || value.symreg.sym === null || isNaN(value.symreg.sym))
                            system.symmetry = null;
                        else
                            system.symmetry = value.symreg.sym;
                    }
                }
                changed = true;
            }
            else if (subChannel === 'nodeRemoved') {
                delete system.inertiaSensors[data];
                changed = true;
            }
            else if (subChannel === 'gnss') {
                for (const [sn, gnssData] of Object.entries(data)) {
                    if (!sn || !gnssData || gnssData.length === 0)
                        return;
                    if (!system.inertiaSensors[sn])
                        system.inertiaSensors[sn] = Object();
                    system.inertiaSensors[sn].gnss = gnssData[gnssData.length - 1];
                }
                changed = true;
            }
            else if (subChannel === 'nodePositions') {
                Object.assign(system.nodePositions, data);
                changed = true;
            }
        }

        else if (channel === 'polar') {
            // console.log('polar data ' + JSON.stringify(data));
            if (subChannel === 'status') {
                system.polarStatus = data;
                if (!system.polarStatus.connected)
                    system.heartRate = null;
                changed = true;
                this.updateLoggingTimer();
            }
            else if (subChannel === 'hr') {
                system.heartRate = data.heartRate;
                changed = true;
            }
        }

        else if (channel === 'system') {
            if (subChannel === 'piJuice') {
                system.piJuice = data;
                changed = true;
            }
        }

        // console.log('channel=' + channel + ', subChannel=' + subChannel + ' data=' + JSON.stringify(data));

        if (changed)
            this.setState({ systems });
    }

    roomHandler(channel, subChannel, data) {
        if (channel === 'response') {
            var systems = this.state.systems;
            var system = undefined;

            if (subChannel === 'list') {
                for (const room of data) {
                    system = systems.find((item) => item.room === room.room);
                    if (!system) {
                        system = JSON.parse(JSON.stringify(defaultSystemInfo));
                        systems.push(system);
                        this.joinRoom(room.room);
                    }
                    Object.assign(system, room);
                }
                systems = systems.filter((system) => data.find((room) => room.room === system.room));
            }
            else if (subChannel === 'join') {
                system = systems.find((item) => item.room === data.room);
                if (system)
                    system.available = true;
            }
            else if (subChannel === 'leave') {
                system = systems.find((item) => item.room === data.room);
                if (system) {
                    system.available = false;
                    system.heartRate = null;
                    system.symmetry = null;
                    system.polarStatus = Object();
                    system.inertiaStatus = Object();
                    system.inertiaSensors = Object();
                    system.piJuice = Object();
                }
            }
            else
                console.log('unhandled channel=' + channel + ', subChannel=' + subChannel + ' data=' + JSON.stringify(data));

            systems.sort((a, b) => {
                var aSystemName = this.state.users.find((item) => item.userID === a.userID)?.username || '';
                var aHorseName = this.state.horses.find((item) => item.systemID === a.userID)?.name || '';
                var bSystemName = this.state.users.find((item) => item.userID === b.userID)?.username || '';
                var bHorseName = this.state.horses.find((item) => item.systemID === b.userID)?.name || '';
                if (aHorseName && bHorseName)
                    return aHorseName.localeCompare(bHorseName);
                else if (aHorseName || bHorseName)
                    return aHorseName ? -1 : 1;
                else if (aSystemName && bSystemName)
                    return aSystemName.localeCompare(bSystemName);
                else if (aSystemName || bSystemName)
                    return aSystemName ? -1 : 1;
                else
                    return 0;
            });
            this.setState({ systems });
        }
        else
            console.log('unhandled channel=' + channel + ', subChannel=' + subChannel + ' data=' + JSON.stringify(data));
    }

    userHandler(channel, subChannel, data) {
        if (channel === 'response') {
            if (subChannel === 'get') {
                this.setState({ users: data });
            }
        }
    }

    horseHandler(channel, subChannel, data) {
        if (channel === 'response') {
            if (subChannel === 'get') {
                this.setState({ horses: data });
            }
            else {
                this.getHorses();
            }
        }
    }

    useForSymmetry(system, sn) {
        var result = false;
        var serialNr = parseInt(sn);

        for (var pos of getSymOrder()) {
            if (!system || !system.nodePositions || !system.sensors)
                break;
            var posSn = system.nodePositions[pos]?.serialNr;
            if (posSn === serialNr)
                result = true;
            var hasAvailableSn = posSn !== 0 && system.inertiaSensors[posSn];
            if (result || hasAvailableSn)
                break;
        }

        return result;
    }

    updateLoggingTimer() {
        var changed = false;
        var systems = this.state.systems;

        for (const system of systems) {
            var logStartTime = null;
            var type = '';

            var hrStatus = system.polarStatus;
            var inStatus = system.inertiaStatus;

            if (inStatus.logging) {
                // normal measurement
                logStartTime = inStatus.logStartTime;
                type = 'normal';
            } else if (hrStatus.logging) {
                // pre/post heart rate
                var post = hrStatus.logStartTime < inStatus.logEndTime;
                if (post) {
                    logStartTime = inStatus.logEndTime;
                    type = 'post-hr';
                } else {
                    logStartTime = hrStatus.logStartTime;
                    type = 'pre-hr';
                }
            }

            var duration = null;
            if (logStartTime) {
                var time = Math.round((Date.now() - logStartTime) / 1000);
                if (time < 0)
                    time = 0; // don't show negative duration when there is a small offset between RPi and viewer time

                var hours = Math.floor(time / 60 / 60);
                var minutes = Math.floor(time / 60 % 60);
                var seconds = Math.floor(time % 60);

                duration = '';
                if (hours > 0)
                    duration += hours + ':';
                duration += (minutes < 10 ? '0' : '') + minutes + ':';
                duration += (seconds < 10 ? '0' : '') + seconds;
            }

            if (duration !== system.loggingDuration || type !== system.loggingType)
                changed = true;
            system.loggingDuration = duration;
            system.loggingType = type;
        }

        if (changed)
            this.setState({ systems });
    }

    onGetRPis() {
        var socket = getSocket();
        socket.emit('room', 'request', 'list');
    }

    joinRoom(room) {
        if (!room)
            return;

        var socket = getSocket();
        if (!socket)
            return;

        socket.emit('room', 'request', 'join', room);
        socket.emit('control', room, 'inertia', 'getInfo');
        socket.emit('control', room, 'polar', 'getInfo');
        socket.emit('control', room, 'system', 'getInfo');
    }

    onCard = (event) => {
        function findAncestor(element, className) {
            while ((element = element.parentElement) && !element.classList.contains(className));
            return element;
        }

        var isCheckbox = findAncestor(event.target, 'MuiCheckbox-root');
        if (isCheckbox)
            return;

        var card = findAncestor(event.target, 'my-card');
        if (!card)
            return;
        var roomID = card.getAttribute('data-room');
        this.props.setSystem(roomID);
        this.setState({ navigate: '/system' });
    }

    renderSystemInfo(system) {
        var available = system.available === true;
        const iconStyle = { fontSize: '1em' };

        const getBatIcon = () => {
            if (!system.piJuice.error)
                return;
            if (system.piJuice.error !== 'NO_ERROR')
                return <BatteryAlertIcon sx={iconStyle} />;
            else if (system.piJuice.data?.battery && system.piJuice.data.battery.startsWith('CHARGING'))
                return <BatteryChargingFullIcon sx={iconStyle} />;
            else
                return <BatteryStdOutlinedIcon sx={iconStyle} />;
        }

        const getBatTD = () => {
            if (!available)
                return <td />;

            var chargeLevel = system.piJuice.battery?.chargeLevel;
            var batColor = (chargeLevel === undefined || chargeLevel >= 25) ? undefined : (chargeLevel >= 10 ? 'orange' : 'red');
            if (system.piJuice.error !== 'NO_ERROR')
                batColor = 'red';

            return (
                <td className='text-left' style={{ color: batColor }}>
                    <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '10px', alignItems: 'center' }}>
                        {getBatIcon()}
                        {chargeLevel !== undefined ? (chargeLevel + '%') : ''}
                    </div>
                </td>
            );
        }

        const getSensorsTD = () => {
            if (!available)
                return <td />;

            var connectionOK = system.inertiaStatus?.connected;
            var sensorStyle = this.getConnectedStyle(connectionOK);
            var sensorInfo = '';
            var gwIcon = connectionOK ? <SettingsInputAntennaIcon sx={iconStyle} /> : <UsbOffIcon sx={iconStyle} />

            if (connectionOK) {
                var sensorCount = 0;
                var assignedCount = 0;
                for (const [sn, sensor] of Object.entries(system.inertiaSensors)) {
                    sensorCount++;
                    for (const pos of Object.values(system.nodePositions)) {
                        if (pos.serialNr === parseInt(sn))
                            assignedCount++;
                    }

                    var validBat = sensor && !isNaN(sensor.bat) && sensor.bat != null;
                    var newBatColor = (!validBat || sensor.bat >= 25) ? undefined : (sensor.bat >= 10 ? 'orange' : 'red');
                    if ((sensorStyle.color === undefined && newBatColor !== undefined) || (sensorStyle.color === 'orange' && newBatColor === 'red'))
                        sensorStyle.color = newBatColor;
                }
                sensorInfo = sensorCount.toString();
                if (sensorCount > assignedCount) {
                    sensorInfo = assignedCount.toString() + '/' + sensorInfo;
                    connectionOK = false;
                }
            }

            return (
                <td style={sensorStyle}>
                    <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '10px', direction: 'rtl', alignItems: 'center' }}>
                        {gwIcon}
                        <div className='text-right'>{sensorInfo}</div>
                    </div>
                </td>
            )
        }

        const getSystemStatus = () => {
            const getDurationColor = () => {
                var color = undefined;
                if (system.loggingType === 'pre-hr' || system.loggingType === 'post-hr')
                    color = getHRcolor();
                return color;
            }

            if (system.loggingDuration) {
                var logMsg = 'logging';
                if (system.loggingType === 'pre-hr' || system.loggingType === 'post-hr')
                    logMsg = 'logging HR';

                return (
                    <td>
                        <Box sx={{ alignSelf: 'center', color: getDurationColor(), fontWeight: 'bold' }}>
                            {logMsg + ': ' + system.loggingDuration}
                        </Box>
                    </td>
                );
            }
            else if (system.inertiaStatus?.syncing) {
                return <td>{'Synchronizing'}</td>;
            }
            else if (system.inertiaStatus?.manualFileSync) {
                return <td>{'Checking Resync'}</td>;
            }
            else {
                return <td style={this.getConnectedStyle(available)}>{available ? 'online' : 'offline'}</td>;
            }
        }

        var systemName = this.state.users.find((item) => item.userID === system.userID)?.username || '\u00a0';
        var horseName = this.state.horses.find((item) => item.systemID === system.userID)?.name || '\u00a0';

        var checkboxDisabled = !available;
        var checkboxChecked = this.checkboxes.find((item) => item.systemID === system.userID)?.checked === true && !checkboxDisabled;

        const onSystemCheckbox = (event) => {
            if (checkboxDisabled)
                return;

            var item = this.checkboxes.find((item) => item.systemID === system.userID);
            if (item) {
                item.checked = event.target.checked;
            } else {
                this.checkboxes.push({
                    systemID: system.userID,
                    checked: event.target.checked,
                });
            }
            localStorage.setItem('systemCheckboxes', JSON.stringify(this.checkboxes));
            this.setState({});
        }

        return (
            <table className='info-table-1'>
                <tbody>
                    <tr>
                        <td style={{ width: '20%' }}>
                            <Box color='text.secondary' className='text-left' sx={{ fontSize: '0.5em' }}>
                                <Checkbox
                                    sx={{
                                        '& .MuiSvgIcon-root': { fontSize: '3em' },
                                        padding: 0,
                                    }}
                                    disabled={checkboxDisabled}
                                    checked={checkboxChecked}
                                    onChange={onSystemCheckbox}
                                />
                                <Box sx={{ paddingLeft: '5px' }}>{systemName}</Box>
                            </Box>
                        </td>
                        <td style={{ width: '60%' }}><Box color='text.primary' sx={{ fontSize: '1.25em' }}>{horseName}</Box></td>
                        <td style={{ width: '20%' }}></td>
                    </tr>
                    <tr>
                        {getBatTD()}
                        {getSystemStatus()}
                        {getSensorsTD()}
                    </tr>
                </tbody>
            </table>
        )
    }

    renderHeartRate(system) {
        var heartRateText = '-';
        var hasHR = false;
        if (system.available) {
            if (system.polarStatus.scanning) {
                heartRateText = 'scanning...';
            }
            else if (!system.polarStatus.connected) {
                heartRateText = 'disconnected';
            } else {
                heartRateText = 'connected';
                if (!isNaN(system.heartRate) && system.heartRate !== null) {
                    heartRateText = system.heartRate.toFixed(0);
                    hasHR = true;
                }
            }
        }

        return (
            <div className='my-sub-item'>
                <span style={{ alignSelf: 'center', fontSize: (hasHR ? '1.5em' : undefined) }}>{heartRateText}</span>
                <span style={{ alignSelf: 'center', fontSize: (hasHR ? '1.5em' : undefined), color: 'red', marginLeft: '10px' }}>{hasHR ? '\u2764' : '\u00a0'}</span>
            </div >
        )
    }

    renderSymmetry(system) {
        var hasSymmetry = system.available && system.inertiaStatus.connected && system.symmetry != null;
        var symmetryText = hasSymmetry ? (system.symmetry * 100).toFixed(0) : '-';

        return (
            <div className='my-sub-item'>
                <span style={{ alignSelf: 'center', fontSize: (hasSymmetry ? '1.5em' : undefined) }}>{symmetryText}</span>
                <span style={{ alignSelf: 'center', fontSize: (hasSymmetry ? '1.5em' : undefined) }}>{hasSymmetry ? '%' : '\u00a0'}</span>
            </div>
        )
    }

    renderSpeed(system) {
        var gnssSensor = undefined;
        if (system.available && system.inertiaStatus.connected) {
            for (const sensor of Object.values(system.inertiaSensors)) {
                var gnss = sensor?.gnss;
                if (gnss && gnss.numberSvsInFix !== undefined && (!gnssSensor || gnss.numberSvsInFix > gnssSensor.numberSvsInFix)) {
                    gnssSensor = gnss;
                }
            }
        }

        const renderSpeedText = () => {
            var hasSpeed = gnssSensor && gnssSensor.valid === true;
            var speedText = '-';
            if (hasSpeed) {
                var minPerKm = 60 / (gnssSensor.speedOverGround * 3.6);
                var minutes = Math.floor(minPerKm);
                var seconds = (minPerKm - minutes) * 60;
                if (minutes < 10)
                    speedText = minutes.toFixed(0) + ':' + seconds.toFixed(0).padStart(2, '0');
                else
                    speedText = '\u221E';
            }
            return (
                <span style={{ alignSelf: 'center', fontSize: (hasSpeed ? '1.5em' : undefined) }}>{speedText}</span>
            )
        }

        const renderSpeedUnit = () => {
            if (!gnssSensor || !gnssSensor.valid)
                return;

            return (
                <span style={{ alignSelf: 'center' }}>
                    <TimerOutlinedIcon sx={{ marginLeft: '3px', fontSize: '1em' }} />
                </span>
            )
        }

        const renderSatellites = () => {
            if (!gnssSensor || gnssSensor.numberSvsInFix === undefined)
                return;

            return (
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr',
                    gap: '10px',
                    direction: 'rtl',
                    alignItems: 'center',
                    color: gnssSensor.numberSvsInFix >= 10 ? undefined : (gnssSensor.numberSvsInFix >= 5 ? 'orange' : 'red'),
                    marginLeft: 'auto',
                    marginRight: '20px'
                }}>
                    <SatelliteAltIcon sx={{ fontSize: '0.75em' }} />
                    <div className='text-right'>{gnssSensor.numberSvsInFix}</div>
                </div>
            )
        }

        return (
            <div className='my-sub-item'>
                {renderSpeedText()}
                {renderSpeedUnit()}
                {renderSatellites()}
            </div>
        )
    }

    renderSystems() {
        const renderedItems = [];

        var hasMeasuringSystems = false;
        for (const system of this.state.systems) {
            if (system.available && system.loggingType)
                hasMeasuringSystems = true;
        }

        const addSystems = (type) => {
            for (const system of this.state.systems) {
                // only add systems of the requested type
                var activeType = (system.available && system.loggingType) ? systemType.measuring : (system.available ? systemType.online : systemType.all);
                if (type !== activeType)
                    continue;

                // only add systems included in selected type
                var showType = this.state.overviewSystemType;
                if (showType === systemType.measuring && !hasMeasuringSystems)
                    showType = systemType.online;
                if (showType > activeType)
                    continue;

                renderedItems.push(
                    <div className='my-card' onClick={this.onCard} key={system.room} data-room={system.room}>
                        <div className='my-item'>{this.renderSystemInfo(system)}</div>
                        <table className='info-table-2'>
                            <tbody>
                                <tr>
                                    <td className='text-right'>{'heart rate: '}</td>
                                    <td>{this.renderHeartRate(system)}</td>
                                </tr>
                                <tr>
                                    <td className='text-right'>{'symmetry: '}</td>
                                    <td>{this.renderSymmetry(system)}</td>
                                </tr>
                                <tr>
                                    <td className='text-right'>{'pace: '}</td>
                                    <td>{this.renderSpeed(system)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                );
            }
        }

        addSystems(systemType.measuring);
        addSystems(systemType.online);
        addSystems(systemType.all);

        return renderedItems;
    }

    getConnectedStyle(available) {
        return {
            color: available ? undefined : 'red',
            fontWeight: available ? undefined : 'bold',
        };
    }

    getServerStatus() {
        const getServerInfo = () => {
            var connected = this.state.isConnected === true;
            if (connected)
                return;

            return (
                <div style={{ marginLeft: '1rem' }}>
                    {'Server: '}
                    <span style={this.getConnectedStyle(connected)}>
                        {(connected ? 'online' : 'offline')}
                    </span>
                </div>
            )
        }

        const getWakeLockInfo = () => {
            if (this.state.wakeLock === 'requested')
                return;
            return (
                <div>
                    {'WakeLock: '}
                    <span>
                        {this.state.wakeLock}
                    </span>
                </div>
            )
        }

        return (
            <div>
                {getServerInfo()}
                {getWakeLockInfo()}
            </div>
        )
    }

    getSystemTypeSelect() {
        const onSelectSystemType = (event) => {
            var overviewSystemType = event?.target?.value;
            this.setState({ overviewSystemType });
            localStorage.setItem('overviewSystemType', overviewSystemType);

            event.preventDefault();
            event.stopPropagation(); // don't close drawer
        };

        const getSystemTypes = () => {
            const renderedItems = [];
            for (const [key, value] of Object.entries(systemType)) {
                var label = value === 2 ? 'Measuring systems' : (value === 1 ? 'Online systems' : (value === 0 ? 'All systems' : key));
                renderedItems.push(
                    <MenuItem key={value} value={value}>{label}</MenuItem>
                );
            }
            return renderedItems;
        }

        return (
            <FormGroup sx={{ padding: '1rem' }}>
                <FormControl fullWidth sx={{ width: 200 }}>
                    <InputLabel id='systems-label'>{'show'}</InputLabel>
                    <Select
                        labelId='systems-label'
                        id='systems-label'
                        value={this.state.overviewSystemType}
                        label='show'
                        onChange={onSelectSystemType}

                    >
                        {getSystemTypes()}
                    </Select>
                </FormControl>
            </FormGroup>
        )
    }

    getZoomButtons() {
        const setZoom = (zoom) => {
            var gridZoom = Math.min(Math.max(zoom, -3), 3);
            localStorage.setItem('gridZoom', gridZoom);
            this.setState({ gridZoom: gridZoom });
        }

        return (
            <ButtonGroup>
                <Button onClick={() => { setZoom(this.state.gridZoom - 1); }}>
                    <RemoveIcon fontSize='small' />
                </Button>
                <Button onClick={() => { setZoom(0); }}>
                    <NotInterestedIcon fontSize='small' />
                </Button>
                <Button onClick={() => { setZoom(this.state.gridZoom + 1); }}>
                    <AddIcon fontSize='small' />
                </Button>
            </ButtonGroup>
        )
    }

    getCheckedSystemInfo() {

        const isInertiaLoggingDisabled = (system) => {
            if (!system.available)
                return true;
            var status = system.inertiaStatus;
            if (!status || !status.connected)
                return true;
            if (system.loggingType === 'post-hr')
                return true;
            if (status.syncing || status.manualFileSync)
                return true;
            return false;
        }

        const isPolarLoggingDisabled = (system, enableForStart) => {
            if (!system.available)
                return true;
            if (system.loggingType === 'normal')
                return true;
            if (enableForStart && system.inertiaStatus && (system.inertiaStatus.syncing || system.inertiaStatus.manualFileSync))
                return true;
            var status = system.polarStatus;
            if (!status || ((status.scanning || !status.connected) && !status.logging))
                return true;
            return false;
        }

        // check if all systems can start or stop a measurement
        var checkedSystemInfo = [];
        for (const system of this.state.systems) {
            if (!this.checkboxes.find((item) => item.systemID === system.userID)?.checked)
                continue; // unchecked
            if (!system.available)
                continue; // checked but offline (shown as unchecked)

            var usedMeasurementID = undefined;
            if (system.polarStatus.logging)
                usedMeasurementID = system.polarStatus.measurementID;
            else if (system.inertiaStatus.logging)
                usedMeasurementID = system.inertiaStatus.measurementID;

            checkedSystemInfo.push({
                inertiaEnable: !isInertiaLoggingDisabled(system),
                polarEnableStart: !isPolarLoggingDisabled(system, true),
                polarEnableStop: !isPolarLoggingDisabled(system, false),
                inertiaStart: !system.inertiaStatus.logging && !system.inertiaStatus.syncing,
                polarStart: !system.polarStatus.logging,
                polarActive: system.polarStatus.connected && !system.polarStatus.scanning,
                roomID: system.room,
                usedMeasurementID: usedMeasurementID,
            });
        }
        return checkedSystemInfo;
    }

    getLoggingButtons() {
        var checkedInfo = this.getCheckedSystemInfo();

        var allInertiaStart = checkedInfo.length > 0 && checkedInfo.every(v => v.inertiaStart === true);
        var allInertiaStop = checkedInfo.length > 0 && checkedInfo.every(v => v.inertiaStart === false);
        var enableInertiaLoggingButton = (allInertiaStart || allInertiaStop) && checkedInfo.length > 0 && checkedInfo.every(v => v.inertiaEnable === true);

        var allPolarStart = checkedInfo.length > 0 && checkedInfo.every(v => v.polarStart === true);
        var allPolarStop = checkedInfo.length > 0 && checkedInfo.every(v => v.polarStart === false);
        var enablePolarLoggingButton = checkedInfo.length > 0 &&
            ((allPolarStart && checkedInfo.every(v => v.polarEnableStart === true)) || (allPolarStop && checkedInfo.every(v => v.polarEnableStop === true)))

        const onToggleInertiaLog = () => {
            if (!enableInertiaLoggingButton)
                return;

            if (Date.now() - this.state.loggingButtonClickTime < loggingButtonTimeout)
                return;
            this.setState({ loggingButtonClickTime: Date.now() });
            setTimeout(() => {
                this.setState({ loggingButtonClickTime: 0 });
            }, loggingButtonTimeout);

            if (allInertiaStart) {
                var measurementID = Date.now() + '_' + this.props.username;
                var trainingTypeID = parseInt(localStorage.getItem('trainingTypeID')) || null

                var socket = getSocket();
                for (var checkedSystem of checkedInfo) {
                    if (checkedSystem.usedMeasurementID)
                        measurementID = checkedSystem.usedMeasurementID;
                    socket.emit('control', checkedSystem.roomID, 'inertia', 'logging', { start: true, measurementID, trainingTypeID });
                    socket.emit('control', checkedSystem.roomID, 'polar', 'logging', { start: true, measurementID, trainingTypeID, startWithoutSensor: true });
                }
            } else if (allInertiaStop) {
                this.setState({ stopConfirm: true });
            }
        }

        const getInertiaLoggingButtonLabel = () => {
            return (enableInertiaLoggingButton && !allInertiaStart) ? 'Stop Measurement' : 'Start Measurement';
        }

        const onTogglePolarLog = () => {
            if (!enablePolarLoggingButton)
                return;

            if (Date.now() - this.state.loggingButtonClickTime < loggingButtonTimeout)
                return;
            this.setState({ loggingButtonClickTime: Date.now() });
            setTimeout(() => {
                this.setState({ loggingButtonClickTime: 0 });
            }, loggingButtonTimeout);

            if (allPolarStart || allPolarStop) {
                var measurementID = Date.now() + '_' + this.props.username;
                var trainingTypeID = parseInt(localStorage.getItem('trainingTypeID')) || null

                var socket = getSocket();
                for (var checkedSystem of checkedInfo) {
                    if (checkedSystem.usedMeasurementID)
                        measurementID = checkedSystem.usedMeasurementID;
                    socket.emit('control', checkedSystem.roomID, 'polar', 'logging', { start: allPolarStart, measurementID, trainingTypeID });
                }
            }
        }

        const getPolarLoggingButtonLabel = () => {
            var inertiaActive = enableInertiaLoggingButton && !allInertiaStart;
            var polarActive = enablePolarLoggingButton && !allPolarStart;
            return (inertiaActive || polarActive) ? 'Stop heart rate' : 'Start heart rate';
        }

        return (
            <Box>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '50%' }} className='text-right'>
                                <Button
                                    sx={{ fontSize: '1.25rem', margin: '10px', marginRight: '1rem' }}
                                    variant='contained'
                                    onClick={onToggleInertiaLog}
                                    disabled={!enableInertiaLoggingButton}
                                >
                                    {getInertiaLoggingButtonLabel()}
                                </Button>
                            </td>
                            <td style={{ width: '50%' }} className='text-left'>
                                <Button
                                    sx={{ fontSize: '1.25rem', margin: '10px', marginLeft: '1rem' }}
                                    variant='contained'
                                    onClick={onTogglePolarLog}
                                    disabled={!enablePolarLoggingButton}
                                >
                                    {getPolarLoggingButtonLabel()}
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Box>
        );
    }

    render() {
        if (this.state.navigate !== undefined) {
            return (
                <Navigate to={this.state.navigate} />
            )
        }

        if (!this.props.loggedIn)
            return;

        var fontSize = 1.5;
        var gridWidth = 450;
        if (this.state.gridZoom <= -3) {
            fontSize = 0.75;
            gridWidth = 200;
        } else if (this.state.gridZoom <= -2) {
            fontSize = 1.0;
            gridWidth = 300;
        } else if (this.state.gridZoom <= -1) {
            fontSize = 1.25;
            gridWidth = 375;
        } else if (this.state.gridZoom >= 3) {
            fontSize = 3.0;
            gridWidth = 600;
        } else if (this.state.gridZoom >= 2) {
            fontSize = 2.5;
            gridWidth = 550;
        } else if (this.state.gridZoom >= 1) {
            fontSize = 2.0;
            gridWidth = 500;
        }

        var gridStyle = {
            gridTemplateColumns: 'repeat(auto-fit, minmax(min(' + gridWidth + 'px, 95%), 1fr))',
            fontSize: fontSize + 'rem',
        };

        const toggleDrawer = (newOpen) => () => {
            this.setState({ drawerOpen: newOpen });
        };

        const onDrawerClick = (event) => {
            var dest = getNavigation(event.target.innerText, window.location.pathname);
            this.setState({
                drawerOpen: false,
                navigate: dest
            });
        };

        const handleStopConfirmClose = () => {
            this.setState({ stopConfirm: false });
        };

        const onStopConfirm = () => {
            var socket = getSocket();

            var checkedInfo = this.getCheckedSystemInfo();
            for (var checkedSystem of checkedInfo) {
                socket.emit('control', checkedSystem.roomID, 'inertia', 'logging', { start: false, });
                if (!checkedSystem.polarActive)
                    socket.emit('control', checkedSystem.roomID, 'polar', 'logging', { start: false, });
            }

            handleStopConfirmClose();
        };

        return (
            <Box sx={{ padding: '0.5rem' }}>
                <ThemeProvider theme={this.defaultTheme}>
                    <CssBaseline />
                    <Drawer open={this.state.drawerOpen} onClose={toggleDrawer(false)}>
                        <Box sx={{ width: 250 }} role='presentation'>
                            <List>
                                {getPages().map((text) => (
                                    <ListItem key={text} disablePadding>
                                        <ListItemButton onClick={onDrawerClick}>
                                            <ListItemText primary={text} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                                <Divider component='li' />
                                <ListItem key={'system-type-select'} disablePadding>
                                    {this.getSystemTypeSelect()}
                                </ListItem>
                            </List>
                        </Box>
                    </Drawer>

                    <Dialog
                        open={this.state.stopConfirm === true}
                        onClose={handleStopConfirmClose}
                    >
                        <DialogContent>
                            {'Are you sure you want to stop the measurement?\n'}
                            {'If a heart rate sensor is active, it will continue logging recovery heart rate data.'}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleStopConfirmClose}>Cancel</Button>
                            <Button onClick={onStopConfirm}>Confirm</Button>
                        </DialogActions>
                    </Dialog>

                    <Box>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '33%' }} className='text-left'>
                                        <Button onClick={toggleDrawer(true)} startIcon={<MenuIcon />}>Menu</Button>
                                    </td>
                                    <td style={{ width: '34%' }} className='text-center'>
                                        <Box sx={{ color: 'primary.main', fontWeight: 'bold', fontSize: '1.5rem' }}>Overview</Box>
                                    </td>
                                    <td style={{ width: '33%' }} className='text-right'>
                                        <Box className='flex-row' sx={{ gap: 0, justifyContent: 'right' }}>
                                            {this.getZoomButtons()}
                                            {this.getServerStatus()}
                                        </Box>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>

                    {this.getLoggingButtons()}

                    <div className='my-cardGrid' style={gridStyle}>
                        {this.renderSystems()}
                    </div>
                </ThemeProvider >
            </Box>

        )
    }
}

export default Overview;
