import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Avatar, Box, Button, CardHeader, CssBaseline, FormControl, FormHelperText, FormLabel, IconButton, InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material';
import MuiCard from '@mui/material/Card';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import {
    ArrowBackRounded as ArrowBackRoundedIcon,
    LockOutlined as LockOutlinedIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';


const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        width: '450px',
    },
    boxShadow:
        'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    ...theme.applyStyles('dark', {
        boxShadow:
            'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    }),
}));


const ChangePassword = (props) => {
    const defaultTheme = createTheme({ palette: { mode: 'dark' } });
    const [usernameErrorMessage, setUsernameErrorMessage] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState('');
    const [signInErrorMessage, setSignInErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();

    const signIn = (username, password, newPassword) => {
        var apiServer = process.env.REACT_APP_API_SERVER;
        fetch(apiServer + '/auth/changepass', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password, newPassword }),
        })
            .then((r) => {
                if (!r.ok)
                    throw new Error(r.status + ': ' + r.statusText);
                return r.json();
            })
            .then((r) => {
                if ('success' === r.message) {
                    props.logIn(false);
                    navigate('/');
                } else {
                    setSignInErrorMessage('Change password failed');
                }
            })
            .catch(e => {
                setSignInErrorMessage('Change password error');
            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        setSignInErrorMessage(false);
        setUsernameErrorMessage(false);
        setPasswordErrorMessage(false);
        setNewPasswordErrorMessage(false);

        const data = new FormData(event.currentTarget);
        var username = data.get('username');
        var password = data.get('password');
        var newPassword = data.get('newPassword');

        var error = false;
        if ('' === username) {
            setUsernameErrorMessage('Please enter your username');
            error = true;
        }
        else if ('' === password) {
            setPasswordErrorMessage('Please enter a password');
            error = true;
        }
        else if ('' === newPassword) {
            setNewPasswordErrorMessage('Please enter a new password');
            error = true;
        }

        if (!error)
            signIn(username, password, newPassword);
    };

    const onBack = (event) => {
        event.preventDefault();
        navigate('/');
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <Stack
                direction='row'
                sx={{
                    justifyContent: 'space-between',
                    position: { xs: 'static', sm: 'fixed' },
                    width: '100%',
                }}
            >
                <Button
                    startIcon={<ArrowBackRoundedIcon />}
                    component='a'
                    onClick={onBack}
                >
                    Back
                </Button>
            </Stack>
            <Stack
                sx={{
                    justifyContent: 'center',
                    height: '100dvh',
                    p: 2
                }}
            >
                <Card variant='outlined'>
                    <CardHeader
                        sx={{ paddingLeft: 0, paddingRight: 0 }}
                        avatar={
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LockOutlinedIcon />
                            </Avatar>
                        }
                        title={
                            <Typography
                                component='h1'
                                variant='h4'
                                sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
                            >
                                Change password
                            </Typography>
                        }
                    />
                    <Box
                        component='form'
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: 2,
                        }}
                    >
                        <FormControl>
                            <FormLabel htmlFor='username'>Username</FormLabel>
                            <OutlinedInput
                                error={usernameErrorMessage.length > 0}
                                id='username'
                                type='username'
                                name='username'
                                placeholder='username'
                                autoFocus
                                required
                                fullWidth
                                variant='outlined'
                                sx={{
                                    color: (usernameErrorMessage.length > 0 ? 'error' : 'primary'),
                                    ariaLabel: 'Username'
                                }}
                                defaultValue={props.username}
                            />
                            {!!usernameErrorMessage && (
                                <FormHelperText error id='username-error'>
                                    {usernameErrorMessage}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor='password'>Old Password</FormLabel>
                            <OutlinedInput
                                error={passwordErrorMessage.length > 0}
                                name='password'
                                placeholder='••••••'
                                type={showPassword ? 'text' : 'password'}
                                id='password'
                                required
                                fullWidth
                                variant='outlined'
                                sx={{
                                    color: (passwordErrorMessage.length > 0 ? 'error' : 'primary'),
                                    ariaLabel: 'Old Password'
                                }}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='toggle password visibility'
                                            onClick={handleClickShowPassword}
                                            edge='end'
                                        >
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {!!passwordErrorMessage && (
                                <FormHelperText error id='password-error'>
                                    {passwordErrorMessage}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor='newPassword'>New Password</FormLabel>
                            <OutlinedInput
                                error={newPasswordErrorMessage.length > 0}
                                name='newPassword'
                                placeholder='••••••'
                                type={showPassword ? 'text' : 'password'}
                                id='newPassword'
                                required
                                fullWidth
                                variant='outlined'
                                sx={{
                                    color: (newPasswordErrorMessage.length > 0 ? 'error' : 'primary'),
                                    ariaLabel: 'New password'
                                }}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='toggle password visibility'
                                            onClick={handleClickShowPassword}
                                            edge='end'
                                        >
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {!!newPasswordErrorMessage && (
                                <FormHelperText error id='new-password-error'>
                                    {newPasswordErrorMessage}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                        >
                            Change password
                        </Button>
                        {signInErrorMessage.length > 0 &&
                            <Alert severity='error'>{signInErrorMessage}</Alert>
                        }
                    </Box>
                </Card>
            </Stack>
        </ThemeProvider>
    );
}

export default ChangePassword;
